import React from 'react';
import {
  Divider,
  Button,
  ButtonEnums,
  Link,
  OnlyDesktop,
  OnlyMobileCSS,
  Dropdown,
  ClapSpinner,
  ModalSizes,
} from 'components/atoms';
import { ReactComponent as IconCheckCircle } from 'resources/images/icon-dev-ic-check-circle.svg';
import { ReactComponent as IconStepperCircle } from 'resources/images/icon-dev-ic-stepper-circle.svg';
import { ReactComponent as IconStepperTwo } from 'resources/images/icon-dev-ic-stepper-two.svg';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { theme } from 'theme';
import { SmartyStreets } from 'mxp-schemas';
import { CheckoutCountriesListHash } from 'mxp-utils';
import { savedShippingAddressCheckedSelector, savedBillingAddressCheckedSelector } from 'modules/checkout/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { Routes } from 'constants/index';
import { getPath, getCurrentLocation } from 'utils';
import { isAuthSelector } from 'modules/user/selectors';
import { isAdminPortalSelector } from 'modules/app/selectors';
import { isFreeTrialInCart } from 'modules/cart/helpers';
import { lineItemsSelector, isFreeCartSelector } from 'modules/cart/selectors';
import { NAV_CLICK, handleEvent } from 'utils/Analytics';
import { setBypassValidation, setBypassValidationBilling } from 'modules/checkout/actions';
import { AddressValidation, handleConfirmAddress } from './ModalAddressValidationHelpers';
import { allowUserToBypassAddressValidationSelector } from 'modules/layouts/selectors';
import { StyledAddressModal } from './StyledAddressModal';

interface SSModalProps {
  billingAddress: State.Address | null;
  billingReason: string | null;
  loading: boolean;
  smartyValidationFetched: boolean;
  isAddressValidationModalOpen: boolean;
  shippingSuggestions: SmartyStreets.ValidationCandidate[];
  shippingAddress: State.Address | null;
  reason: string | null;
  isRaveCheckout: boolean;
  billingSuggestions: SmartyStreets.ValidationCandidate[];
  isCustomAddress?: boolean;
  // actions
  toggleModalAddressValidation: (
    isAddressValidationModalOpen: boolean,
    allowUserToBypassAddressValidation: State.AddressValidationBypassFlags
  ) => void;
  setShippingAddress?: (modifier: Partial<State.Address>) => void;
  goToPayment?: (addressSelected?: boolean) => Promise<void>;
  setSavedShippingAddressEdited?: (payload: boolean) => void;
  setSavedBillingAddressEdited?: (payload: boolean) => void;
  setBillingAddress?: (modifier: Partial<State.Address>) => void;
  validateAddress?: (address: State.Address) => Promise<void>;
  setSuggestedAddress?: (modifier: Partial<State.Address>) => void;
}

export const ModalAddressValidation: React.FC<SSModalProps> = ({
  billingAddress,
  billingReason,
  billingSuggestions,
  loading,
  smartyValidationFetched,
  isAddressValidationModalOpen,
  shippingSuggestions,
  shippingAddress,
  reason,
  isRaveCheckout,
  // actions
  toggleModalAddressValidation,
  setShippingAddress,
  goToPayment,
  setSavedShippingAddressEdited,
  setSavedBillingAddressEdited,
  setBillingAddress,
  validateAddress,
  setSuggestedAddress,
}) => {
  const dispatch = useDispatch();
  const savedShippingAddressChecked = useSelector(savedShippingAddressCheckedSelector);
  const savedBillingAddressChecked = useSelector(savedBillingAddressCheckedSelector);
  const isImpersonation = useSelector(isAuthSelector);
  const isAdminPortal = useSelector(isAdminPortalSelector);
  const isB2BC = isAdminPortal && isImpersonation;
  const cartItems = useSelector(lineItemsSelector);
  const isFreeCart = useSelector(isFreeCartSelector);

  // Address checks
  const isAddressAmbiguous: boolean = reason === SmartyStreets.ValidationReason.AMBIGUOUS;
  const isAddressCleanupNeeded: boolean = reason === SmartyStreets.ValidationReason.CLEAN_UP;
  const isSecAddressUnrecognized: boolean = reason === SmartyStreets.ValidationReason.SECONDARY_ADD_UNRECOGNIZED;
  // billing Checks
  const isBillingAmbiguous: boolean = billingReason === SmartyStreets.ValidationReason.AMBIGUOUS;
  const isBillingAddressCleanupNeeded: boolean = billingReason === SmartyStreets.ValidationReason.CLEAN_UP;
  const isSecBillingAddressUnrecognized: boolean =
    billingReason === SmartyStreets.ValidationReason.SECONDARY_ADD_UNRECOGNIZED;

  const isSingleSuggestion: boolean = isAddressCleanupNeeded || isSecAddressUnrecognized;
  const isBillingSingleSuggestion: boolean = isBillingAddressCleanupNeeded || isSecBillingAddressUnrecognized;

  const singleSuggestion: SmartyStreets.ValidationCandidate | null = shippingSuggestions?.[0] || null;
  const billingSingleSuggestion: SmartyStreets.ValidationCandidate | null = billingSuggestions?.[0] || null;

  // Will check if the state is already included on address lines
  // Will add if not
  const checkAddressState = (shortSuggestion: any, fullAddressSuggestion: any) => {
    if (!Boolean(shortSuggestion?.deliveryLine3) && !Boolean(shortSuggestion?.deliveryLine4)) return '';
    const addressTest = shortSuggestion?.deliveryLine3 + shortSuggestion?.deliveryLine4;
    const includeState = addressTest?.includes(fullAddressSuggestion?.fullSuggestion.state);
    return !includeState ? fullAddressSuggestion?.fullSuggestion?.state : '';
  };

  const location = useLocation();
  const currentLocation = getCurrentLocation(location);

  const triggerAnalyticsClickEvent = React.useCallback(() => {
    let destination = currentLocation;
    if (isSingleSuggestion) {
      if (currentLocation === getPath(Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_PERSONAL)) {
        destination = getPath(Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_EMPLOYMENT);
      }
      if (currentLocation === getPath(Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_EMPLOYMENT)) {
        destination = getPath(Routes.CIMA_MEMBERSHIP_APPLICATION_FORM_TRAINING);
      }
    }

    handleEvent({ clickValue: `button:link:int::Confirm Address:${destination}` }, NAV_CLICK);
  }, [currentLocation, isSingleSuggestion]);

  const validationModelReady: boolean =
    smartyValidationFetched &&
    (isAddressAmbiguous ||
      isAddressCleanupNeeded ||
      isSecAddressUnrecognized ||
      isBillingAmbiguous ||
      isBillingAddressCleanupNeeded ||
      isSecBillingAddressUnrecognized);

  const addressSuggestion = React.useMemo(() => {
    const shortSuggestion = singleSuggestion?.shortSuggestion;
    const generalAddressLine1 = `${singleSuggestion?.fullSuggestion?.street}`;
    const country: string = shortSuggestion?.country
      ? CheckoutCountriesListHash[shortSuggestion.country].text
      : shortSuggestion?.country || '';
    return {
      addressLine1: isSecAddressUnrecognized ? generalAddressLine1 : shortSuggestion?.deliveryLine1,
      addressLine2: shortSuggestion?.deliveryLine2 || '',
      addressLine3: shortSuggestion?.deliveryLine3 || '',
      addressLine4: shortSuggestion?.deliveryLine4 || '',
      country,
      state: checkAddressState(shortSuggestion, singleSuggestion),
    };
  }, [singleSuggestion, isSecAddressUnrecognized]);

  const billingAddressSuggestion = React.useMemo(() => {
    const shortSuggestion = billingSingleSuggestion?.shortSuggestion;
    const generalAddressLine1 = `${billingSingleSuggestion?.fullSuggestion?.street}`;
    const country: string = shortSuggestion?.country
      ? CheckoutCountriesListHash[shortSuggestion.country].text
      : shortSuggestion?.country || '';
    return {
      addressLine1: isSecBillingAddressUnrecognized ? generalAddressLine1 : shortSuggestion?.deliveryLine1,
      addressLine2: shortSuggestion?.deliveryLine2 || '',
      addressLine3: shortSuggestion?.deliveryLine3 || '',
      addressLine4: shortSuggestion?.deliveryLine4 || '',
      country,
      state: checkAddressState(shortSuggestion, billingSingleSuggestion),
    };
  }, [billingSingleSuggestion, isSecBillingAddressUnrecognized]);

  const addressInputByUser = React.useMemo(() => {
    const isOptionalIncluded: boolean = Boolean(shippingAddress?.addressLine2);
    const country: string = shippingAddress?.country
      ? CheckoutCountriesListHash[shippingAddress.country]?.text
      : shippingAddress?.country || '';
    return {
      addressLine1: `${shippingAddress?.addressLine1}${isOptionalIncluded ? ` ${shippingAddress?.addressLine2}` : ''}`,
      addressLine2: `${shippingAddress?.city} ${shippingAddress?.state} ${shippingAddress?.zipCode}`,
      country,
    };
  }, [shippingAddress]);

  const billingAddressInputByUser = React.useMemo(() => {
    const isOptionalIncluded: boolean = Boolean(billingAddress?.addressLine2);
    const country: string = billingAddress?.country
      ? CheckoutCountriesListHash[billingAddress.country]?.text
      : billingAddress?.country || '';
    return {
      addressLine1: `${billingAddress?.addressLine1}${isOptionalIncluded ? ` ${billingAddress?.addressLine2}` : ''}`,
      addressLine2: `${billingAddress?.city} ${billingAddress?.state} ${billingAddress?.zipCode}`,
      country,
    };
  }, [billingAddress]);

  const addressSuggestions = React.useMemo(() => {
    const suggestions = isAddressAmbiguous
      ? shippingSuggestions.map((suggestion: SmartyStreets.ValidationCandidate, index: number) => {
          const { deliveryLine1, deliveryLine2, country } = suggestion?.shortSuggestion;

          const suggestionStr = [
            deliveryLine1,
            deliveryLine2,
            CheckoutCountriesListHash[country]?.text || country,
          ].join(',\n');

          return {
            key: `${deliveryLine1}-${index}`,
            text: suggestionStr,
            value: index,
          };
        })
      : [];
    return suggestions;
  }, [shippingSuggestions, isAddressAmbiguous]);

  const billingAddressSuggestions = React.useMemo(() => {
    const suggestions = isBillingAmbiguous
      ? billingSuggestions.map((suggestion: SmartyStreets.ValidationCandidate, index: number) => {
          const { deliveryLine1, deliveryLine2, country } = suggestion?.shortSuggestion;
          const suggestionStr = [
            deliveryLine1,
            deliveryLine2,
            CheckoutCountriesListHash[country]?.text || country,
          ].join(',\n');
          return {
            key: `${deliveryLine1}-${index}`,
            text: suggestionStr,
            value: index,
          };
        })
      : [];
    return suggestions;
  }, [billingSuggestions, isBillingAmbiguous]);

  const isShippingAddressValid: boolean = !(isAddressAmbiguous || isAddressCleanupNeeded || isSecAddressUnrecognized);
  const isBillingAddressValid: boolean = !(
    isBillingAmbiguous ||
    isBillingAddressCleanupNeeded ||
    isSecBillingAddressUnrecognized
  );

  // Multi-Address Handling - for when both shipping address and billing address are invalid

  const areShippingAndBillingAddressesInvalid = !isBillingAddressValid && !isShippingAddressValid;
  // If user is non-US + CIMA, they can either accept suggested address or existing address. If US or AICPA, can only accept suggested.
  const [isSuggestedShippingAddressAccepted, setIsSuggestedShippingAddressAccepted] = React.useState(false);
  // Next step is used to indicate which address is being worked on at a given time
  // It is only when both billing address and shipping address are invalid, that this comes into play.
  // is nextStep is false, we're working on a single invalid address (it may be shipping or billing). If true, billing address.
  const [nextStep, setNextStep] = React.useState(false);

  // Handling for address validation bypassing
  const allowUserToBypassAddressValidation = useSelector(allowUserToBypassAddressValidationSelector);

  const [dropdownSelection, setDropdownSelection] = React.useState({
    shippingAddress: '',
    billingAddress: '',
  });

  const [loadingButtonType, setLoadingButtonType] = React.useState<AddressValidation>();
  const confirmBtnDisabled: boolean =
    loading ||
    (!isShippingAddressValid && !nextStep
      ? isAddressAmbiguous && dropdownSelection.shippingAddress === ''
      : isBillingAmbiguous && dropdownSelection.billingAddress === '');

  const suggestedAddressText =
    !isShippingAddressValid && !nextStep
      ? !isSingleSuggestion
        ? 'Suggested addresses:'
        : 'Suggested address:'
      : !isBillingSingleSuggestion
      ? 'Suggested addresses:'
      : 'Suggested address:';

  const DynamicAddressText =
    !isShippingAddressValid && !isBillingAddressValid
      ? 'addresses'
      : isShippingAddressValid
      ? 'billing address'
      : 'shipping address';

  const DynamicSuggestionText =
    !isShippingAddressValid && !nextStep
      ? !isSingleSuggestion
        ? 'Please select a suggested address'
        : 'Please confirm our suggested address'
      : !isBillingSingleSuggestion
      ? 'Please select a suggested address'
      : 'Please confirm our suggested address';

  const headerAddressText =
    !isShippingAddressValid && !isBillingAddressValid && !nextStep
      ? 'Shipping Address'
      : !isShippingAddressValid && nextStep
      ? 'Billing Address'
      : '';

  const closeMatchText =
    !isShippingAddressValid && !nextStep
      ? !isSingleSuggestion
        ? 'several close matches'
        : 'a close match'
      : !isBillingSingleSuggestion
      ? 'several close matches'
      : 'a close match';

  const isConfirmExistingAddressButtonVisible =
    // Scenario: first address to validate is shipping. This is for both single and multi-step validation.
    (allowUserToBypassAddressValidation.allowShippingAddressValidationBypass && !isShippingAddressValid && !nextStep) ||
    // Scenario: first address to validate is billing. This is for only single address validation.
    (allowUserToBypassAddressValidation.allowBillingAddressValidationBypass &&
      !isBillingAddressValid &&
      isShippingAddressValid &&
      !nextStep) ||
    // Scenario: second address to validate is billing. This is for only multi address validation.
    (allowUserToBypassAddressValidation.allowBillingAddressValidationBypass && !isBillingAddressValid && nextStep);

  const resetState = React.useCallback(() => {
    setNextStep(false);
    setDropdownSelection({
      shippingAddress: '',
      billingAddress: '',
    });
    const letUserBypassAddressValidation = {
      allowShippingAddressValidationBypass: false,
      allowBillingAddressValidationBypass: false,
    };
    toggleModalAddressValidation(false, letUserBypassAddressValidation);
  }, [toggleModalAddressValidation]);

  const resetBypassValidation = React.useCallback(() => {
    // If bypass validation is set to true by Invalid Address modal - reset address page to existing state
    dispatch(setBypassValidation(false));
    dispatch(setBypassValidationBilling(false));
  }, [dispatch]);

  const completeValidationOrGoToPayment = React.useCallback(
    async (customAddress: State.Address | undefined) => {
      // if isFreeProductOnlyInCart = true, confirming address will skip the payment step since free product ($0 Non-Free Trial) does not require payment method.
      const isFreeTrialProductInCart = isFreeTrialInCart(cartItems);
      const isFreeProductOnlyInCart = isFreeCart && !isFreeTrialProductInCart;

      if (goToPayment) await goToPayment(isFreeProductOnlyInCart);
      else {
        if (validateAddress && customAddress) {
          validateAddress(customAddress);
        }
      }
      resetState();
      triggerAnalyticsClickEvent();
    },
    [cartItems, goToPayment, isFreeCart, triggerAnalyticsClickEvent, validateAddress, resetState]
  );

  const transformToCustomAddress = React.useCallback(
    (selectedAddress: SmartyStreets.ValidationCandidate): State.Address => {
      const { street, city, state, zipcode, streetSec, county, country } = selectedAddress.fullSuggestion;

      return {
        addressLine1: street,
        addressLine2: streetSec || '',
        city,
        county,
        state,
        zipCode: zipcode,
        country: country || CheckoutCountriesListHash.USA.ISOAlpha3Code,
      };
    },
    []
  );

  const handleAddressUpdate = React.useCallback(
    (
      savedAddressChecked: boolean,
      customAddress: State.Address,
      setAddress?: (payload: State.Address) => void,
      setSavedAddressEdited?: (payload: boolean) => void
    ) => {
      if (setAddress) {
        if (savedAddressChecked && setSavedAddressEdited) {
          setSavedAddressEdited(true);
        }
        setAddress(customAddress);
      }
    },
    []
  );

  const handleUserConfirmationOfShippingAddress = React.useCallback(
    (isSuggestedShippingAddressAccepted: boolean): State.Address | undefined => {
      if (!isSuggestedShippingAddressAccepted) {
        // Bypass validation for existing address
        dispatch(setBypassValidation(true));
        return undefined;
      }

      const selectedShippingAddress = isSingleSuggestion
        ? shippingSuggestions[0]
        : shippingSuggestions[Number(dropdownSelection.shippingAddress)];

      const customShippingAddress = transformToCustomAddress(selectedShippingAddress);

      if (setShippingAddress) {
        handleAddressUpdate(
          savedShippingAddressChecked,
          customShippingAddress,
          setShippingAddress,
          setSavedShippingAddressEdited
        );
      } else if (setSuggestedAddress) {
        setSuggestedAddress(customShippingAddress);
      }

      return customShippingAddress;
    },
    [
      shippingSuggestions,
      isSingleSuggestion,
      dropdownSelection,
      handleAddressUpdate,
      setShippingAddress,
      setSuggestedAddress,
      setSavedShippingAddressEdited,
      savedShippingAddressChecked,
      dispatch,
      transformToCustomAddress,
    ]
  );

  const handleUserConfirmationOfBillingAddress = React.useCallback(
    async (isSuggestedBillingAddressAccepted: boolean) => {
      if (!isSuggestedBillingAddressAccepted) {
        // Bypass validation for existing address
        dispatch(setBypassValidationBilling(true));
        return;
      }

      const selectedBillingAddress = isBillingSingleSuggestion
        ? billingSuggestions[0]
        : billingSuggestions[Number(dropdownSelection.billingAddress)];

      const customBillingAddress = transformToCustomAddress(selectedBillingAddress);

      if (setBillingAddress) {
        handleAddressUpdate(
          savedBillingAddressChecked,
          customBillingAddress,
          setBillingAddress,
          setSavedBillingAddressEdited
        );
      }
    },
    [
      billingSuggestions,
      transformToCustomAddress,
      handleAddressUpdate,
      dropdownSelection.billingAddress,
      isBillingSingleSuggestion,
      savedBillingAddressChecked,
      setBillingAddress,
      setSavedBillingAddressEdited,
      dispatch,
    ]
  );

  const handleFirstAddressConfirmation = React.useCallback(
    async (isSuggestedAddress: boolean) => {
      // 'nextStep' in this instance tracks whether the user has validated the first address.
      setNextStep(true);
      triggerAnalyticsClickEvent();
      // This is either suggested or existing address
      setIsSuggestedShippingAddressAccepted(isSuggestedAddress);
    },
    [triggerAnalyticsClickEvent]
  );

  const handleGoBackAndEdit = React.useCallback(() => {
    resetState();
    resetBypassValidation();
  }, [resetState, resetBypassValidation]);

  const handleDropdownOnChange = React.useCallback(
    (e, data: any) => {
      const { name, value } = data;
      setDropdownSelection({ ...dropdownSelection, [name]: `${value}` });
    },
    [dropdownSelection, setDropdownSelection]
  );

  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const onDropdownOpen = React.useCallback(() => {
    setIsDropdownOpen(true);
  }, [setIsDropdownOpen]);
  const onDropdownClose = React.useCallback(() => {
    setIsDropdownOpen(false);
  }, [setIsDropdownOpen]);

  return (
    <StyledAddressModal
      size={ModalSizes.TINY}
      heading={!validationModelReady ? 'One moment...' : `Let's double check`}
      open={isAddressValidationModalOpen}
      testId="address-validation-extra"
      centered={false} // For IE11.
    >
      {!validationModelReady ? (
        <StyledClapSpinnerContainer>
          <ClapSpinner
            size={45}
            frontColor={theme.colors.primaryPurple}
            backColor={theme.colors.primaryPurple}
            loading={loading}
          />
        </StyledClapSpinnerContainer>
      ) : (
        <div>
          <SubHeader>
            {`We found ${closeMatchText} to the ${DynamicAddressText} you entered.
            ${DynamicSuggestionText}, or go back and edit your address.`}
          </SubHeader>
          {!isShippingAddressValid && !isBillingAddressValid && (
            <StyledList data-testid="validation-progress-bar">
              <StyledListItem data-testid="validation-progress-bar--step" className={!nextStep ? 'current' : 'done'}>
                {!nextStep ? (
                  <IconStepperCircle data-testid="step-circle" />
                ) : (
                  <IconCheckCircle data-testid="check-circle" />
                )}
                <span>Step 1</span>
              </StyledListItem>
              <StyledListItem data-testid="validation-progress-bar--step" className={nextStep ? 'current' : ''}>
                {!nextStep ? (
                  <IconStepperTwo data-testid="step-two" />
                ) : (
                  <IconStepperCircle data-testid="step-circle" />
                )}
                <span>Step 2</span>
              </StyledListItem>
            </StyledList>
          )}
          <StyledTitle>{headerAddressText}</StyledTitle>
          <AddressBlock>
            <StyledSubheading>{'You entered:'}</StyledSubheading>
            <AddressFields
              addressLine1={
                !isShippingAddressValid && !nextStep
                  ? addressInputByUser.addressLine1
                  : billingAddressInputByUser.addressLine1
              }
              addressLine2={
                !isShippingAddressValid && !nextStep
                  ? addressInputByUser.addressLine2
                  : billingAddressInputByUser.addressLine2
              }
              country={
                !isShippingAddressValid && !nextStep ? addressInputByUser.country : billingAddressInputByUser.country
              }
            />
            <StyledSubheading>{suggestedAddressText}</StyledSubheading>
            {!isShippingAddressValid && !nextStep ? (
              isSingleSuggestion ? (
                <AddressFields
                  addressLine1={addressSuggestion.addressLine1}
                  addressLine2={addressSuggestion.addressLine2}
                  addressLine3={addressSuggestion.addressLine3}
                  addressLine4={addressSuggestion.addressLine4}
                  country={addressSuggestion.country}
                  state={addressSuggestion.state}
                />
              ) : (
                addressSuggestions.length > 0 && (
                  <DropdownBlock>
                    <StyledDropdown
                      testId="modal-shipping-address-dropdown"
                      name="shippingAddress"
                      placeholder="Select address"
                      fluid
                      selection
                      options={addressSuggestions}
                      value={dropdownSelection.shippingAddress && Number(dropdownSelection.shippingAddress)}
                      onChange={handleDropdownOnChange}
                      onOpen={onDropdownOpen}
                      onClose={onDropdownClose}
                      isActive={isDropdownOpen}
                    />
                  </DropdownBlock>
                )
              )
            ) : isBillingSingleSuggestion ? (
              <AddressFields
                addressLine1={billingAddressSuggestion.addressLine1}
                addressLine2={billingAddressSuggestion.addressLine2}
                addressLine3={billingAddressSuggestion.addressLine3}
                addressLine4={billingAddressSuggestion.addressLine4}
                country={billingAddressSuggestion.country}
                state={billingAddressSuggestion.state}
              />
            ) : (
              billingAddressSuggestions.length > 0 && (
                <DropdownBlock>
                  <StyledDropdown
                    testId="modal-billing-address-dropdown"
                    name="billingAddress"
                    placeholder="Select address"
                    fluid
                    selection
                    options={billingAddressSuggestions}
                    value={dropdownSelection.billingAddress && Number(dropdownSelection.billingAddress)}
                    onChange={handleDropdownOnChange}
                    onOpen={onDropdownOpen}
                    onClose={onDropdownClose}
                    isActive={isDropdownOpen}
                  />
                </DropdownBlock>
              )
            )}
            <IncorrectHelp isRaveCheckout={isRaveCheckout} isB2BC={isB2BC} />
          </AddressBlock>
        </div>
      )}
      <Divider />
      <ActionBlock>
        <StyledOnlyMobile>
          <Button
            overrideAnalytics={true}
            testId={`modal-add-verification-confirm`}
            variant={ButtonEnums.variants.primary}
            size={ButtonEnums.sizes.small}
            onClick={() =>
              handleConfirmAddress(
                AddressValidation.suggested,
                areShippingAndBillingAddressesInvalid,
                nextStep,
                isShippingAddressValid,
                isBillingAddressValid,
                isSuggestedShippingAddressAccepted,
                setLoadingButtonType,
                handleFirstAddressConfirmation,
                handleUserConfirmationOfShippingAddress,
                handleUserConfirmationOfBillingAddress,
                completeValidationOrGoToPayment
              )
            }
            loading={loading && loadingButtonType === AddressValidation.suggested}
            disabled={confirmBtnDisabled}
          >
            Confirm suggested address
          </Button>
          {isConfirmExistingAddressButtonVisible && (
            <StyledButton
              testId={`modal-add-verification-confirm-existing-address`}
              variant={ButtonEnums.variants.secondary}
              size={ButtonEnums.sizes.small}
              onClick={() =>
                handleConfirmAddress(
                  AddressValidation.existing,
                  areShippingAndBillingAddressesInvalid,
                  nextStep,
                  isShippingAddressValid,
                  isBillingAddressValid,
                  isSuggestedShippingAddressAccepted,
                  setLoadingButtonType,
                  handleFirstAddressConfirmation,
                  handleUserConfirmationOfShippingAddress,
                  handleUserConfirmationOfBillingAddress,
                  completeValidationOrGoToPayment
                )
              }
              loading={loading && loadingButtonType === AddressValidation.existing}
              disabled={confirmBtnDisabled}
            >
              Confirm existing address
            </StyledButton>
          )}
          <Button
            testId={`modal-add-verification-go-back`}
            variant={ButtonEnums.variants.secondary}
            size={ButtonEnums.sizes.small}
            onClick={handleGoBackAndEdit}
            disabled={loading}
          >
            Go back and edit address
          </Button>
        </StyledOnlyMobile>
        <StyledOnlyDesktop>
          <Button
            testId={`modal-add-verification-go-back`}
            variant={ButtonEnums.variants.secondary}
            size={ButtonEnums.sizes.small}
            onClick={handleGoBackAndEdit}
            disabled={loading}
          >
            Go back and edit address
          </Button>
          {isConfirmExistingAddressButtonVisible && (
            <StyledButton
              testId={`modal-add-verification-confirm-existing-address`}
              variant={ButtonEnums.variants.secondary}
              size={ButtonEnums.sizes.small}
              onClick={() =>
                handleConfirmAddress(
                  AddressValidation.existing,
                  areShippingAndBillingAddressesInvalid,
                  nextStep,
                  isShippingAddressValid,
                  isBillingAddressValid,
                  isSuggestedShippingAddressAccepted,
                  setLoadingButtonType,
                  handleFirstAddressConfirmation,
                  handleUserConfirmationOfShippingAddress,
                  handleUserConfirmationOfBillingAddress,
                  completeValidationOrGoToPayment
                )
              }
              loading={loading && loadingButtonType === AddressValidation.existing}
              disabled={confirmBtnDisabled}
            >
              Confirm existing address
            </StyledButton>
          )}
          <Button
            overrideAnalytics={true}
            testId={`modal-add-verification-confirm`}
            variant={ButtonEnums.variants.primary}
            size={ButtonEnums.sizes.small}
            onClick={() =>
              handleConfirmAddress(
                AddressValidation.suggested,
                areShippingAndBillingAddressesInvalid,
                nextStep,
                isShippingAddressValid,
                isBillingAddressValid,
                isSuggestedShippingAddressAccepted,
                setLoadingButtonType,
                handleFirstAddressConfirmation,
                handleUserConfirmationOfShippingAddress,
                handleUserConfirmationOfBillingAddress,
                completeValidationOrGoToPayment
              )
            }
            loading={loading && loadingButtonType === AddressValidation.suggested}
            disabled={confirmBtnDisabled}
          >
            Confirm suggested address
          </Button>
        </StyledOnlyDesktop>
      </ActionBlock>
    </StyledAddressModal>
  );
};

const AddressFields = React.memo<{
  addressLine1: string | undefined;
  addressLine2: string | undefined;
  addressLine3?: string;
  addressLine4?: string;
  country: string | undefined;
  state?: string | undefined;
}>(({ addressLine1, addressLine2, addressLine3, addressLine4, country, state }) => {
  return (
    <StyledAddress>
      <p>{addressLine1}</p>
      <p>{addressLine2}</p>
      {
        <p>
          {addressLine3 || ''} {state || ''} {addressLine4 || ''}
        </p>
      }
      <p>{country}</p>
    </StyledAddress>
  );
});

const IncorrectHelp = React.memo<{ isRaveCheckout: boolean; isB2BC: boolean | null }>(({ isRaveCheckout, isB2BC }) => {
  return (
    <ModalFooter>
      <span>Still incorrect?</span>
      {!isB2BC ? (
        <p>
          Contact us by selecting the chat icon to the right of your screen. Or call us at{' '}
          <Link isExternal to={'tel:8887777077'} textUnderline={true}>
            888-777-7077
          </Link>
          &nbsp; or &nbsp;
          <Link isExternal to={'tel:+19194024500'} textUnderline={true}>
            +1 919-402-4500
          </Link>
          &nbsp; if outside the U.S. to complete your order.
        </p>
      ) : (
        <p>
          Please contact your customer to confirm the address. If the customer address is valid, please raise an{' '}
          <Link isExternal testId="error-email" to="mailto:help@aicpa-cima.com">
            IT helpdesk ticket
          </Link>
          .
        </p>
      )}
    </ModalFooter>
  );
});

const StyledOnlyMobile = styled(OnlyMobileCSS)`
  &&&&& {
    button {
      width: 100%;
    }
    button:first-child {
      margin-bottom: ${props => props.theme.pxToRem(12)};
    }
  }
`;

const StyledButton = styled(Button)`
  &&&&& {
    margin-bottom: ${props => props.theme.pxToRem(12)};
  }
`;

const StyledOnlyDesktop = styled(OnlyDesktop)`
  &&&&& {
    button:first-child {
      margin-right: ${props => props.theme.pxToRem(14)};
    }
  }
`;

const DropdownBlock = styled.div`
  &&&&& {
    margin-top: ${props => props.theme.pxToRem(12)};
    margin-bottom: ${props => props.theme.pxToRem(30)};
    font-size: ${props => props.theme.pxToRem(16)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      white-space: pre-line;
    }
    div {
      font-size: ${props => props.theme.pxToRem(14)};
    }

    div.text {
      padding-left: ${props => props.theme.pxToRem(10)};
      padding-right: ${props => props.theme.pxToRem(10)};
    }

    div.default {
      color: ${props => props.theme.colors.primaryPurple};
      padding-left: ${props => props.theme.pxToRem(10)};
      padding-right: ${props => props.theme.pxToRem(10)};
      font-weight: ${props => props.theme.fontWeights.regular};
    }

    div.item {
      padding-left: ${props => props.theme.pxToRem(20)}!important;
      padding-right: ${props => props.theme.pxToRem(20)}!important;
      font-weight: ${props => props.theme.fontWeights.light};
    }

    div.menu {
      span {
        color: ${props => props.theme.colors.neutralGrey8};
        line-height: 1.57;
        ${props => props.theme.mediaQueries.mobileOnly} {
          white-space: pre-line;
        }
      }
    }
  }
`;

const AddressBlock = styled.div`
  text-align: left;
`;

const ActionBlock = styled.div`
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-bottom: ${props => props.theme.pxToRem(-12)};
  }
  margin-bottom: ${props => props.theme.pxToRem(-23)};
  button {
    min-width: ${props => props.theme.pxToRem(212)};
  }
`;

const ModalFooter = styled.div`
  font-size: ${props => props.theme.fontSizes.xxs};
  span {
    font-weight: ${props => props.theme.fontWeights.medium};
  }
  p {
    padding: ${props => props.theme.pxToRem(3)} 0 ${props => props.theme.pxToRem(26)};
    ${props => props.theme.mediaQueries.desktopOnly} {
      padding-right: ${props => props.theme.pxToRem(80)};
    }
    ${props => props.theme.mediaQueries.mobileOnly} {
      padding: ${props => props.theme.pxToRem(3)} 0 ${props => props.theme.pxToRem(5)};
    }
  }
`;

const StyledSubheading = styled.div`
  font-size: ${props => props.theme.pxToRem(16)};
  font-weight: ${props => props.theme.fontWeights.medium};
  padding-bottom: ${props => props.theme.pxToRem(5)};
`;

const SubHeader = styled.div`
  padding-bottom: ${props => props.theme.pxToRem(34)};
  white-space: pre-line;
`;

const StyledAddress = styled.div`
  margin-bottom: ${props => props.theme.pxToRem(22)};
  p {
    margin: 0 0 ${props => props.theme.pxToRem(3)};
  }
`;

const StyledClapSpinnerContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: ${props => props.theme.pxToRem(350)};
  justify-content: center;
  align-items: center;
`;

const StyledList = styled.ul`
  position: relative;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin: 0;
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.theme.fontWeights.light};
  list-style: none;
`;

const StyledListItem = styled.li`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 0 ${props => props.theme.pxToRem(12)};
  margin-right: ${props => props.theme.pxToRem(24)};
  background-color: ${props => props.theme.colors.neutralWhite};

  ::after {
    position: absolute;
    content: '';
    width: 24px;
    right: -24px;
    border-bottom: ${props => props.theme.pxToRem(1)} solid ${props => props.theme.colors.neutralGrey3};
  }

  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0 ${props => props.theme.pxToRem(6)};
    margin-right: ${props => props.theme.pxToRem(12)};

    :not(.current) {
      span {
        display: none;
      }

      > svg {
        margin-right: 0;
      }
    }
  }

  > svg {
    margin-right: ${props => props.theme.pxToRem(10)};

    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-right: ${props => props.theme.pxToRem(4)};
    }
  }

  &.current {
    color: ${props => props.theme.colors.neutralGrey8};
    font-size: ${props => props.theme.fontSizes.xs};
    font-weight: ${props => props.theme.fontWeights.medium};
    line-height: 1.57;

    ${props => props.theme.mediaQueries.mobileOnly} {
      font-size: ${props => props.theme.fontSizes.xxs};
      line-height: 1.5;
      letter-spacing: ${props => props.theme.pxToRem(0.22)};
    }
  }

  &.done,
  &.current {
    > svg path {
      fill: ${props => props.theme.colors.secondaryTeal};
    }
  }
  :not(.current):not(.done) {
    > svg path {
      fill: ${props => props.theme.colors.neutralGrey5};
    }
  }

  &:last-child {
    margin-right: 0;

    ::after {
      display: none;
    }
  }
`;

const StyledTitle = styled.h2`
  font-family: inherit;
  font-size: ${props => props.theme.fontSizes.l};
  text-align: left;
  color: ${props => props.theme.colors.neutralGrey8};
  line-height: 1.33;
  font-weight: ${props => props.theme.fontWeights.light};
  margin: ${props => props.theme.pxToRem(28)} auto ${props => props.theme.pxToRem(21)};
`;

const StyledDropdown = styled(Dropdown)`
  &&&&& {
    display: flex;

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      width: ${props => props.theme.pxToRem(44)};
      height: ${props => props.theme.pxToRem(72)};
      border-top-right-radius: ${props => props.theme.pxToRem(4)};
      border-bottom-right-radius: ${props => props.theme.pxToRem(4)};
      border-left: solid 1px ${props => props.theme.colors.neutralGrey4};
      background-color: ${props =>
        props.isActive ? props.theme.colors.primaryPurple : props.theme.colors.neutralGrey2};
      width: ${props => props.theme.pxToRem(44)};

      ${props => props.theme.mediaQueries.desktopOnly} {
        height: ${props => props.theme.pxToRem(72)};
      }

      ${props => props.theme.mediaQueries.mobileOnly} {
        height: ${props => props.theme.pxToRem(99)};
      }
    }

    width: 100%;
    border-radius: ${props => props.theme.pxToRem(4)};
    border-color: ${props => props.theme.colors.neutralGrey4};
    border-bottom-left-radius: ${props => (props.isActive ? '0' : props.theme.pxToRem(4))};

    ${props => props.theme.mediaQueries.desktopOnly} {
      height: ${props => props.theme.pxToRem(74)};
      padding: ${props =>
        `${props.theme.pxToRem(15)} ${props.theme.pxToRem(61)} ${props.theme.pxToRem(13)} ${props.theme.pxToRem(16)}`};
    }

    ${props => props.theme.mediaQueries.mobileOnly} {
      height: ${props => props.theme.pxToRem(101)};
      padding: ${props =>
        `${props.theme.pxToRem(15)} ${props.theme.pxToRem(61)} ${props.theme.pxToRem(13)} ${props.theme.pxToRem(10)}`};
    }

    & > .text {
      width: 100%;
      color: ${props => props.theme.colors.primaryPurple};
      & > div {
        display: flex;
        width: 100%;
        justify-content: space-between;
        color: ${props => props.theme.colors.neutralGrey8};
      }
    }

    & > .menu {
      margin-right: ${props => props.theme.pxToRem(43)};
      border-bottom-left-radius: ${props => props.theme.pxToRem(4)};
      border-bottom-right-radius: ${props => props.theme.pxToRem(4)};
    }

    svg {
      width: ${props => props.theme.pxToRem(12)};
      height: ${props => props.theme.pxToRem(9)};
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: ${props => props.theme.pxToRem(15)};
      path {
        fill: ${props => (props.isActive ? props.theme.colors.neutralGrey2 : props.theme.colors.primaryPurple)};
      }
    }

    &.active {
      border-color: ${props => props.theme.colors.primaryPurple};
    }
  }

  .item {
    display: flex;
    justify-content: space-between;
    align-items: start;

    &::before {
      content: '';
      display: block;
      margin: 0 ${props => props.theme.pxToRem(16)};
      border-top: 1px solid ${props => props.theme.colors.neutralGrey3};
      position: relative;
      top: -${props => props.theme.pxToRem(14)};
      margin-right: 0;
      margin-left: 0;
    }
  }

  &.dropdown i {
    margin-right: 0.5625rem;
    font-size: ${props => props.theme.fontSizes.s};
  }

  &.ui.dropdown .menu .item {
    ${props => props.theme.mediaQueries.desktopOnly} {
      padding: ${props =>
        `${props.theme.pxToRem(15)} ${props.theme.pxToRem(16)} ${props.theme.pxToRem(13)} ${props.theme.pxToRem(
          16
        )} !important`};
    }

    ${props => props.theme.mediaQueries.mobileOnly} {
      padding: ${props =>
        `${props.theme.pxToRem(15)} ${props.theme.pxToRem(16)} ${props.theme.pxToRem(13)} ${props.theme.pxToRem(
          10
        )} !important`};
    }

    &:first-of-type {
      padding-top: ${props => props.theme.pxToRem(15)} !important;
    }
    &:last-of-type {
      padding-bottom: ${props => props.theme.pxToRem(13)} !important;
    }
  }

  &.visible .menu:before {
    border: none;
  }

  &&&.upward {
    ${props => props.theme.mediaQueries.mobileOnly} {
      border-bottom-left-radius: ${props => props.theme.pxToRem(4)};
      border-top-left-radius: ${props => (props.isActive ? '0' : props.theme.pxToRem(4))};

      &.visible .menu {
        border-top: 1px solid ${props => props.theme.colors.primaryPurple};
        border-bottom: none;
        .item:first-of-type {
          &::before {
            display: none;
          }
        }
      }
    }
  }
`;
