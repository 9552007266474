import { createMatchSelector, getLocation, push } from 'connected-react-router';
import { PAYMENT_PROCESSING_TIMEOUT, Routes } from 'constants/index';
import { addSalesforceAddress, convertCountryToCode } from 'modules/address/actions';
import { isAdminPortalSelector } from 'modules/app/selectors';
import { removeCartItems } from 'modules/cart';
import { setCartShippingAddress } from 'modules/cart/actions';
import {
  cartIdSelector,
  cartShippingAddressSelector,
  getMembershipApplicationTypeSelector,
  hasCentersProductSelector,
  hasCredentialProductSelector,
  hasFcmaCredentialSelector,
  hasMembershipProductSelector,
  hasSectionProductSelector,
  isFreeCartSelector,
  lineItemsSelector,
  selectedMembershipSelector,
  hasFlpProductSelector,
  cartDetailItemsSelector,
  hasItemWithThreeMonthRuleProrationSelector,
  cartSelector,
  isCimaApprenticeLapsedRenewalSelector,
  flpLineItemSelector,
  isL7CandidateOrAffiliateCoreUpgradeSelector,
  membershipLineItemSelector,
  isCimaApprenticeSuspendedRenewalSelector,
  cartPONumberSelector,
  isApprenticeCimaRegularLapsedRenewalSelector,
  isApprenticeCimaAffiliateLapsedRenewalSelector,
  isApprenticeCimaResignedRenewalSelector,
  shouldRemoveProrationSelector,
  isActiveFcmaRenewalSelector,
} from 'modules/cart/selectors';
import { USE_OFFLINE_PAYMENT_OPTIONS, USE_VITAL_SOURCE_PRICE_CORRECTION } from 'modules/featureToggle/constants';
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import { toggleModalAddressValidationOpen, toggleModalPaymentProcessingOpen } from 'modules/layouts';
import { setSubscriptionRenewalToggleLoading, toggleModalInvalidAddressOpen } from 'modules/layouts/actions';
import { cardBeingProcessedSelector } from 'modules/layouts/selectors';
import {
  activeMembershipSubscriptionSelector,
  isCenterMembershipJourneySelector,
  isCenterMembershipRenewalSelector,
  isRenewalSelector,
  productsToBeDisabledIfMembershipIsDisabledSelector,
  sectionsCredentialsRenewalSelector,
  userMembershipTypeSelector,
  activeFlpSubscriptionSelector,
  isCimaMipJourneySelector,
  isFLPUpgradeSelector,
  isFLPSwitchSelector,
  membershipInviteDataSelector,
  clickedMembershipUpgradeSelector,
  isFlpRenewalSelector,
  isFLPSwitchOrUpgradeSelector,
  isMipRenewalSeasonSelector,
  practicalExperienceRequirementStatusSelector,
  clickedSectionRenewalSelector,
  isApprenticeCandidateForRenewalSelector,
  isApprenticeRegularForRenewalSelector,
  isApprenticeAffiliateForRenewalSelector,
  currentMembershipProduct,
  currentMembershipEntitySelector,
  clickedCimaMembershipRenewalSelector,
  activeMemSecCredSubscriptionSelector,
} from 'modules/membership/selectors';
import {
  hasUserActiveStandingOrderSelector,
  hasUserActiveSubscriptionsSelector,
  hasUserAwaitingShipmentOrderSelector,
  hasExistingZuoraPurchaseSelector,
  hasMipCredentialProductSelector,
  claimGiftAidSelector,
  productsListDataLineItemsSelector,
} from 'modules/products/selectors';
import {
  getRenewalSeasonOverrideSelector,
  getRenewalDateOverrideSelector,
  savePaypalSelector,
} from 'modules/startup/selectors';
import { updateApplication } from 'modules/user/actions';
import {
  addressBookSelector,
  applicationSelector,
  attestationSelector,
  centerMembershipApplicationAdminDetailSelector,
  centerMembershipApplicationObjectSelector,
  centerMembershipPackageOrganizationSelector,
  isAuthSelector,
  personAccountDataSelector,
  userFirstNameSelector,
  userLastNameSelector,
  currentJourneyLearningPathwaySelector,
  customerMembershipsSelector,
  allAddressesSelector,
  isUserAffiliateSelector,
  hasActiveQuoteFromFirmSelector,
  hasFirmBillingRelationSelector,
  hasCimaFirmBillingRelationSelector,
  primaryAddressSelector,
  customerCredentialsSelector,
  isEPA1CompletedSelector,
  isEPA2CompletedSelector,
  gatewayStatusSelector,
  cimaMembershipTermIsTenYearsSelector,
  isInLatestCycleSelector,
  learningPathwaySelector,
} from 'modules/user/selectors';
import {
  isOrgInvoiceCheckoutSelector,
  isPaypalProceedInvoiceCheckoutSelector,
  isPaypalProceedFirmBillingCheckoutSelector,
  legalEntityQuerySelector,
  isPageFirmBillingInvoiceCheckoutSelector,
} from 'modules/router/selectors';
import moment from 'moment-timezone';
import {
  CREATE_ZUORA_ORDER,
  GET_ADDRESS_AUTOCOMPLETE_ITEMS,
  GET_CONFERENCE_USER_INFO,
  GET_HOSTED_PAGE_SIGNATURE,
  GET_ORDER_PREVIEW,
  GET_PAYMENTS_ID,
  GET_PAYMENT_BY_ID,
  GET_RECEIPT,
  GET_RECEIPT_PAYMENT_CONFIRMATION_DETAILS,
  GET_ZUORA_ACCOUNT,
  MAKE_RECEIPT_PAYMENT,
  MUTATE_PAYMENT_METHOD_EMAIL,
  PREVIEW_CANCEL_MEMBERSHIP_ORDER,
  REMOVE_PAYMENT_METHOD,
  SEND_CREDIT_CARD_EMAIL,
  SET_CONFERENCE_USER_INFO,
  SUBSCRIPTION_TOGGLE,
  UPDATE_PAYMENT_INFORMATION,
  UPDATE_PAYMENT_INFORMATION_BY_LEGALENTITY,
  UPDATE_ZUORA_ACCOUNT,
  GET_MULTI_HOSTED_PAGE_SIGNATURE,
  CREATE_MANDATE,
  UPDATE_MANDATE,
  DELETE_MANDATE,
  GET_PAYPAL_TOKEN,
  CREATE_PAYPAL_PAYMENT_METHOD,
  MUTATE_SEND_ACH_MANDATE_CONFIRMATION_EMAIL,
  MUTATE_SEND_DD_MANDATE_CONFIRMATION_EMAIL,
  UPDATE_OTHER_PAYMENT_METHOD,
  UPDATE_ZUORA_INVOICE_OWNER,
  GET_CONTENTFUL_ATTESTATIONS,
  UPDATE_ALL_ZUORA_INVOICE_OWNER,
} from 'mxp-graphql-queries';
import {
  CommerceTypes,
  Invoices,
  MembershipTypes,
  Orders,
  Product,
  Salesforce,
  SmartyStreets,
  ZuoraTypes,
  SFMC,
  User,
} from 'mxp-schemas';
import {
  Admin as AdminUtils,
  CheckoutCountriesListHash,
  Order as OrderUtils,
  CountriesList,
  ConvertFromISOAlpha3ToText,
  checkQualificationStatus,
  checkIfAddressIsAmerica,
  User as UserUtils,
  FirmAdmin,
} from 'mxp-utils';
import { generatePath, matchPath } from 'react-router-dom';
import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';
import { emptyObject, getPath, hasTruthyValue } from 'utils';
import { newAddressKey } from './constants';
import { default as request, updateContext } from 'utils/GraphQLClient';
import {
  forceNonProrationPrices,
  getCTAddress,
  getLocalCheckoutAction,
  getSmartyStreetsValidation,
  getZuoraAddresses,
  parseAddressResponses,
  getUserMembershipEntity,
  createZuoraOrderHelper,
  getLearningPathway,
  getLearningPathwayProductInCart,
  isFLPTierBeingChangedToCore,
  getValidationBypassFlags,
  shouldDisplayInvalidAddressModal,
  shouldDisplaySuggestedAddressModal,
  handleSecondaryAddress,
} from './helpers';
import {
  baseBillingAddressSelector,
  baseShippingAddressSelector,
  billingAddressSelector,
  getReceiptSelector,
  isBillingAddressValidSelector,
  isDefaultBillingAddressExistSelector,
  isShippingAddressValidSelector,
  membershipProratedAmountSelector,
  otherPaymentMethodSelector,
  sameShippingAddressForBillingSelector,
  savedBillingAddressCheckedSelector,
  savedBillingAddressSelector,
  savedShippingAddressCheckedSelector,
  savedShippingAddressSelector,
  selectedPaymentMethodEntity,
  shippingAddressSelector,
  checkoutBillingAddressSelector,
  checkoutShippingAddressSelector,
  paypalSessionIdentifierSelector,
  isModifyingEntitySelector,
  isSelectedCardSavedSelector,
  saveCreditCardForFutureSelector,
  hasDefaultPaymentMethodSelector,
} from './selectors';
import { fullCimaInvoiceDetailsSelector, fullInvoiceDetailsSelector } from 'modules/clientAdmin/selectors';
import {
  isAdminInvoicesPaymentJourneySelector,
  legalEntitySelector,
  selectedInvoicesSelector,
} from 'modules/admin/selectors';
import { setLegalEntity } from 'modules/admin/actions';
import {
  membershipRefundSelector,
  selectedCurrencySelector,
  legalEntitySelector as checkoutEntitySelector,
} from 'modules/checkout/selectors';
import { inviteDataSelector, selectedOrganizationSelector } from 'modules/firmAdmin/selectors';
import { handleEvent } from 'utils/Analytics';
import { ADD_PAYMENT_INFO } from 'utils/Analytics/constants';
import { isUserCpaLicensedSelector } from 'modules/personLevelExemption/selectors';

// ------------------------------------
// Actions
// ------------------------------------
export const loading: any = createAction('checkout/LOADING');

export const getLocalCheckout: any = createAction('checkout/GET_LOCAL', getLocalCheckoutAction);
export const setSavedShippingAddressEdited: any = createAction('checkout/SET_SAVED_SHIPPING_ADDRESS_EDIT');
export const setSavedBillingAddressEdited: any = createAction('checkout/SET_SAVED_BILLING_ADDRESS_EDIT');
export const setShippingEmbargoEdited: any = createAction('checkout/SET_SHIPPING_EMBARGO_EDIT');
export const setBillingEmbargoEdited: any = createAction('checkout/SET_BILLING_EMBARGO_EDIT');
export const setBypassValidation: any = createAction('checkout/SET_BYPASS_VALIDATION');
export const setBypassValidationBilling: any = createAction('checkout/SET_BYPASS_VALIDATION_BILLING');
export const setShippingAddress: any = createAction(
  'checkout/SET_SHIPPING_ADDRESS',
  (address: Partial<State.Address>) => async (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(loading());
    const state = getState();

    if ('city' in address || 'country' in address) {
      dispatch(setShippingEmbargoEdited(true));
    }

    const newShippingAddress = baseShippingAddressSelector(state);
    if (address?.country && address?.country !== state.checkout.shippingAddress.country) {
      await dispatch(
        setCartShippingAddress({
          ...newShippingAddress,
          ...address,
          addressLine2: address.addressLine2 || '',
        })
      );
    }

    return address;
  }
);

export const setBillingAddress: any = createAction(
  'checkout/SET_BILLING_ADDRESS',
  (address: Partial<State.Address>) => async (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(loading());

    if ('city' in address || 'country' in address) {
      dispatch(setBillingEmbargoEdited(true));
    }

    return address;
  }
);

export const setSelectedCreditCardId: any = createAction('checkout/SET_SELECTED_CREDIT_CARD_ID');

export const toggleSameShippingAddressForBilling: any = createAction(
  'checkout/TOGGLE_SAME_SHIPPING_FOR_BILLING',
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const firstName: string = userFirstNameSelector(state);
    const lastName: string = userLastNameSelector(state);
    return { firstName, lastName };
  }
);

export const toggleSameShippingAddressForPayment: any = createAction('checkout/TOGGLE_SAME_SHIPPING_FOR_PAYMENT');

export const toggleSavedShippingAddressChecked: any = createAction(
  'checkout/TOGGLE_SAVED_SHIPPING_ADDRESS_CHECK',
  async (id: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    let newShippingAddress: State.Address | null = id
      ? id !== newAddressKey
        ? convertCountryToCode(addressBookSelector(state).find(add => add.id === id)) ||
          savedShippingAddressSelector(state)
        : savedShippingAddressSelector(state)
      : baseShippingAddressSelector(state);

    // add default address type
    if (newShippingAddress) newShippingAddress.addressType = Salesforce.HomeAddressType.OFFICE_MAILING;
    // if shippingAddress saved and different from cart update cart with new shipping address
    if (newShippingAddress) {
      const cartAddress = cartShippingAddressSelector(state);
      const savedAddressSameWithCartAddress = isSavedAddressSameWithCartAddress(cartAddress, newShippingAddress);

      // if savedShippingAddress is checked
      if (!state.checkout.savedShippingAddressChecked && !savedAddressSameWithCartAddress && id !== 'NEW') {
        dispatch(setCartShippingAddress(convertCountryToCode(getCTAddress(newShippingAddress))));
        return newShippingAddress;
      }
    }
    // if use different shipping address is checked
    if (id === newAddressKey) {
      dispatch(setCartShippingAddress(getCTAddress(newShippingAddress)));
      if (newShippingAddress) {
        newShippingAddress = {
          ...newShippingAddress,
          id: newAddressKey,
        };
      }
    }

    return newShippingAddress || baseShippingAddressSelector(state);
  }
);

export const isSavedAddressSameWithCartAddress = (
  cartAddress: CommerceTypes.Address | undefined,
  savedAddress: State.Address
): boolean => {
  return !(
    cartAddress?.country !== CheckoutCountriesListHash?.[savedAddress.country]?.key ||
    cartAddress?.streetName !== savedAddress.addressLine1 ||
    cartAddress?.streetNumber !== savedAddress.addressLine2 ||
    cartAddress?.city !== savedAddress.city ||
    cartAddress?.state !== savedAddress.state ||
    cartAddress?.postalCode !== savedAddress.zipCode
  );
};

export const toggleSavedBillingAddressChecked: any = createAction(
  'checkout/TOGGLE_SAVED_BILLING_ADDRESS_CHECK',
  async (id: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const currentBillingAddress: State.Address | null = id
      ? convertCountryToCode(addressBookSelector(state).find(add => add.id === id)) || null
      : savedBillingAddressSelector(state);

    if (currentBillingAddress) currentBillingAddress.addressType = Salesforce.HomeAddressType.OFFICE_MAILING;
    dispatch(setBillingAddress(currentBillingAddress || {}));
    return id;
  }
);

export const toggleIsLoadingAutocompleteItems: any = createAction('checkout/TOGGLE_IS_LOADING_AUTOCOMPLETE_ITEMS');

export const toggleSaveCreditCardForFuture: any = createAction('checkout/TOGGLE_SAVE_CREDIT_CARD_FOR_FUTURE');

export const setCheckoutPage: any = createAction('checkout/SET_CHECKOUT_PAGE');

export const setSelectedPaymentMethodEntity: any = createAction('checkout/SET_SELECTED_PAYMENT_METHOD_ENTITY');

export const resetSalesTax: any = createAction('checkout/RESET_SALES_TAX');

export const setConferenceUserInfo: any = createAction(
  'checkout/SET_CONFERENCE_USER_INFO',
  (userInfo: State.ConferenceUserInfo) => async (dispatch: Dispatch) => {
    dispatch(loading());
    return request(SET_CONFERENCE_USER_INFO, { info: userInfo }).then(response => response.setConferenceUserInfo);
  }
);

export const getConferenceUserInfo: any = createAction(
  'checkout/GET_CONFERENCE_USER_INFO',
  () => async (dispatch: Dispatch) => {
    dispatch(loading());
    return request(GET_CONFERENCE_USER_INFO).then(response => response.getConferenceUserInfo);
  }
);

const useMultiEntity = async (legalEntity: string) => {
  const customHeaders = {
    existingEntity: legalEntity.toLowerCase(),
  };
  return updateContext(customHeaders);
};

export const getZuoraAccount: any = createAction(
  'checkout/GET_ZUORA_ACCOUNT',
  (
      skipAccountCreation?: boolean,
      isPaymentPage?: boolean,
      isCreateOrder?: boolean,
      isOtherPayments?: boolean,
      isFirmInviteAccepted?: boolean,
      paymentMethodId?: string
    ) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      dispatch(loading());
      try {
        const state = getState();
        const isPaypalProceedInvoiceCheckout = isPaypalProceedInvoiceCheckoutSelector(state);
        const isPaypalFirmBilling = isPaypalProceedFirmBillingCheckoutSelector(state);

        const orgId = isPaypalFirmBilling
          ? fullInvoiceDetailsSelector(state)?.accountId
          : fullCimaInvoiceDetailsSelector(state).find(item => item.accountId)?.accountId;
        const cart = cartSelector(state);

        const legalEntity = !isPaypalProceedInvoiceCheckout
          ? selectedPaymentMethodEntity(state)
          : legalEntitySelector(state);

        const isModifyingEntity = isModifyingEntitySelector(state);

        if (!isFirmInviteAccepted && legalEntity) await useMultiEntity(legalEntity as string);
        if (isModifyingEntity?.isModifying) await useMultiEntity(isModifyingEntity?.legalEntity as string);

        const params: any = {
          skipAccountCreation,
          isCreateOrder,
          isOtherPayments,
          isFirmInviteAccepted,
          paymentMethodId,

          // consider the active cart's currency and pass as an argument so that there will be no mistake with the currency when zuora account is created inside the GET_ZUORA_ACCOUNT
          ...(cart?.totalPrice?.currencyCode && { cartCurrency: cart?.totalPrice?.currencyCode }),
        };
        if ((isPaypalProceedInvoiceCheckout || isPaypalFirmBilling) && orgId) {
          params.orgInvoiceCheckout = { orgId };
        }

        const response = await request(GET_ZUORA_ACCOUNT, { ...params });
        const isCenterMembershipJourney = isCenterMembershipJourneySelector(state);
        const selectedOrganization = centerMembershipPackageOrganizationSelector(state);
        // other side effect must be applied for checkout route only
        const { pathname } = getLocation(state);
        const isCheckoutRoute =
          Boolean(matchPath(pathname, { path: getPath(Routes.CHECKOUT_PAGE), exact: true })) ||
          Boolean(matchPath(pathname, { path: getPath(Routes.PAYPAL_PAYMENT_CHECKOUT_PROCEED), exact: true }));
        if (!isCheckoutRoute) return response.getZuoraAccount;

        let hasExistingAddressFromAddressBook = false;
        const id = addressBookSelector(state)?.[0]?.id;
        hasExistingAddressFromAddressBook = Boolean(id);

        return {
          ...response.getZuoraAccount,
          selectedOrganization,
          isCenterMembershipJourney,
          hasExistingAddressFromAddressBook,
        };
      } catch (error) {
        console.error(`getZuoraAccount ${error}`);
        throw error;
      }
    }
);

export const getPaymentById: any = createAction(
  'checkout/GET_PAYMENT_BY_ID',
  (paymentId: string) => (dispatch: Dispatch) => {
    dispatch(loading());
    return request(GET_PAYMENT_BY_ID, { paymentId }).then(response => {
      return response.getPaymentById;
    });
  }
);

export const resetOfflineVerify: any = createAction('checkout/RESET_OFFLINE_VERIFY');

export const createZuoraOrder: any = createAction(
  'checkout/CREATE_ZUORA_ORDER',
  (
      paymentMethodId?: string,
      paymentOption?: ZuoraTypes.PaymentOption,
      membershipAutoRenewEnabled?: boolean,
      optForAutoRenewProducts?: Orders.RenewableProductsStatus[],
      paymentMethod?: string,
      options?: { checkIfUnpublishedProduct?: boolean }
    ) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      const state: State.Root = getState();
      const startTime: number = Date.now();
      const { isNoPayment, isCheckPayment } = OrderUtils.getAllPaymentOptionsCheck(
        paymentOption as ZuoraTypes.PaymentOption
      );
      const isFreeCartPayment: boolean = isFreeCartSelector(state);
      const poNumber: string = cartPONumberSelector(state);
      const noPaymentNeeded: boolean = FirmAdmin.hasTruthyValue(isNoPayment, isFreeCartPayment, isCheckPayment);

      dispatch(toggleModalPaymentProcessingOpen());

      await createZuoraOrderHelper.handleUnpublishedProduct(options, isFreeCartPayment, dispatch);

      if (isFreeCartPayment) {
        dispatch(loading());
      }

      const getTimeoutDiff = (): number => {
        const endTime: number = Date.now();
        const diff: number = endTime - startTime;
        return UserUtils.conditionalFunction(diff > PAYMENT_PROCESSING_TIMEOUT, 0, PAYMENT_PROCESSING_TIMEOUT - diff);
      };

      const shippingAddress = checkoutShippingAddressSelector(state);
      const billingAddress = checkoutBillingAddressSelector(state);
      const { id: sfAccountId } = personAccountDataSelector(state);
      const savePaypal = savePaypalSelector(state);
      const cartLineItems = lineItemsSelector(state);
      const saveCreditCardForFuture = saveCreditCardForFutureSelector(state);
      const hasDefaultPaymentMethod = hasDefaultPaymentMethodSelector(state);
      const isSelectedCardSaved = isSelectedCardSavedSelector(state);

      const organizationAccount = centerMembershipPackageOrganizationSelector(state);
      const adminAccountDetail = centerMembershipApplicationAdminDetailSelector(state);
      const attestationTexts = attestationSelector(state);
      const isCenterMembershipJourney = isCenterMembershipJourneySelector(state);
      const isFLPUpgrade = isFLPUpgradeSelector(state);
      const isFLPSwitch = isFLPSwitchSelector(state);
      const isInLatestCycle = isInLatestCycleSelector(state);

      const activeMembershipSubscription =
        activeMembershipSubscriptionSelector(state) ?? activeFlpSubscriptionSelector(state);

      const flpLineItem = flpLineItemSelector(state);
      const activeFLPSubscription = activeFlpSubscriptionSelector(state);
      const applicationObject = centerMembershipApplicationObjectSelector(state);
      const productSlug = userMembershipTypeSelector(state);
      const { isPCAOBOrganization } = centerMembershipPackageOrganizationSelector(state);
      const isCenterMembershipRenewal = isCenterMembershipRenewalSelector(state);
      const membershipProratedPrice = membershipProratedAmountSelector(state);
      const sectionsCredentialsRenewal = sectionsCredentialsRenewalSelector(state);
      const hasMembershipProduct = hasMembershipProductSelector(state);
      const membershipApplicationType = getMembershipApplicationTypeSelector(state);
      const isRenewalSeasonOverride = getRenewalSeasonOverrideSelector(state);
      const { association }: State.PaymentMethods = otherPaymentMethodSelector(state);
      const isClickedMembershipUpgrade = clickedMembershipUpgradeSelector(state);
      const hasFLPProduct = hasFlpProductSelector(state);

      const learningPathway = getLearningPathway(hasMembershipProduct, hasFLPProduct, state);
      const isL7CandidateOrAffiliateCoreUpgrade = isL7CandidateOrAffiliateCoreUpgradeSelector(state);
      const renewalDateOverride = getRenewalDateOverrideSelector(state);
      const membershipRefund = membershipRefundSelector(state);
      const isFlpRenewal = isFlpRenewalSelector(state);
      const primaryAddressCountry = primaryAddressSelector(state)?.country;
      const isEPA1Completed = isEPA1CompletedSelector(state);
      const isEPA2Completed = isEPA2CompletedSelector(state);
      const isEPAsCompleted = FirmAdmin.areAllTruthy(isEPA1Completed, isEPA2Completed);
      const isPERCompleted =
        practicalExperienceRequirementStatusSelector(state) === MembershipTypes.PERPortfolioStatus.COMPLETED;
      const cimaMembershipTermIsTenYears = cimaMembershipTermIsTenYearsSelector(state);
      const isUserCpaLicensed = isUserCpaLicensedSelector(state);
      const claimGiftAid = claimGiftAidSelector(state);
      const isMipCredentialRenewal = FirmAdmin.areAllTruthy(
        hasMipCredentialProductSelector(state),
        isMipRenewalSeasonSelector(state)
      );
      const hasItemWithThreeMonthRuleProration = hasItemWithThreeMonthRuleProrationSelector(state);
      const checkProduct = currentMembershipProduct(state);
      const membershipTermData = checkProduct?.membership?.membershipTerm;
      const isOrderPaidByFirm = Boolean(membershipTermData?.isPaidByFirm === 'true');
      // firm billing
      const isUserAffiliate = isUserAffiliateSelector(state);
      const hasActiveQuoteFromFirm = hasActiveQuoteFromFirmSelector(state);
      const hasFirmBillingRelation = hasFirmBillingRelationSelector(state);
      const hasCimaFirmBillingRelation = hasCimaFirmBillingRelationSelector(state);
      const clickedSectionRenewalEvent = clickedSectionRenewalSelector(state);
      const inviteData = inviteDataSelector(state);
      const hasExistingZuoraPurchase = hasExistingZuoraPurchaseSelector(state);
      // toggle for vitalSource newest update for price changes
      const useVitalSourcePriceCorrection = getFeatureToggleByKeySelector(state, USE_VITAL_SOURCE_PRICE_CORRECTION);

      const isUserFBUpgradingMembership = FirmAdmin.areAllTruthy(
        isUserAffiliate, // if membership is affiliate
        isClickedMembershipUpgrade, // if from upgrade
        !hasActiveQuoteFromFirm, // continue only if there's no active quote
        FirmAdmin.hasTruthyValue(hasFirmBillingRelation, hasCimaFirmBillingRelation)
      );

      const isCimaApprenticeLapsedRenewal = isCimaApprenticeLapsedRenewalSelector(state);
      const isApprenticeCimaRegularLapsedRenewal = isApprenticeCimaRegularLapsedRenewalSelector(state);
      const isApprenticeCimaAffiliateLapsedRenewal = isApprenticeCimaAffiliateLapsedRenewalSelector(state);
      const isCimaApprenticeSuspendedRenewal = isCimaApprenticeSuspendedRenewalSelector(state);
      const isApprenticeCandidateForRenewal = isApprenticeCandidateForRenewalSelector(state);
      const isApprenticeCimaResignedRenewal = isApprenticeCimaResignedRenewalSelector(state);
      const isApprenticeRegularForRenewal = isApprenticeRegularForRenewalSelector(state);
      const isApprenticeAffiliateForRenewal = isApprenticeAffiliateForRenewalSelector(state);

      const enrollmentStatus = gatewayStatusSelector(state)?.enrollmentStatus as string;

      const isFlpSwitchOrUpgrade = FirmAdmin.hasTruthyValue(isFLPSwitch, isFLPUpgrade);
      // Will check if the used payment is ach
      const isAchPayment = association?.ach?.some(achAccount => achAccount.id === paymentMethodId);
      const isActiveMembershipAutoRenewEnabled = activeMembershipSubscription?.autoRenewEnabled;
      const credentialSectionOptForAutoRenew = optForAutoRenewProducts?.some(item =>
        FirmAdmin.areAllTruthy(
          item.optForAutoRenew,
          FirmAdmin.hasTruthyValue(
            item.productType === Product.ProductType.SECTION,
            item.productType === Product.ProductType.CREDENTIAL
          )
        )
      );
      const shouldEnableAutoRenewForActiveMembership = FirmAdmin.areAllTruthy(
        !isActiveMembershipAutoRenewEnabled,
        credentialSectionOptForAutoRenew
      );
      const isCIMA = membershipApplicationType === Product.MembershipApplicationType.CIMA;
      const qualificationLevelStatus: string = checkQualificationStatus(
        hasMembershipProduct,
        isCIMA,
        isUserCpaLicensed,
        learningPathway,
        hasTruthyValue(
          isCimaApprenticeLapsedRenewal,
          isCimaApprenticeSuspendedRenewal,
          isApprenticeCandidateForRenewal,
          isApprenticeCimaRegularLapsedRenewal,
          isApprenticeCimaAffiliateLapsedRenewal,
          isApprenticeCimaResignedRenewal,
          isApprenticeRegularForRenewal,
          isApprenticeAffiliateForRenewal
        ),
        enrollmentStatus
      );

      const isRenewalButtonClicked = clickedCimaMembershipRenewalSelector(state);
      const isFlpProductRenewal = OrderUtils.checkFlpProductRenewal(
        isFlpRenewal,
        activeFLPSubscription,
        flpLineItem,
        isRenewalButtonClicked
      );
      const productsToBeDisabledIfMembershipIsDisabled = productsToBeDisabledIfMembershipIsDisabledSelector(state);

      // add togglesubscription for membership related subscriptions when customer opt out with auto renew in type change/manual renewal.
      await createZuoraOrderHelper.handleSubscriptionRenewalToggle(
        activeMembershipSubscription,
        membershipAutoRenewEnabled,
        shouldEnableAutoRenewForActiveMembership,
        hasFLPProduct,
        productsToBeDisabledIfMembershipIsDisabled,
        productSlug,
        dispatch
      );

      const isPrimaryAddressAmericas = checkIfAddressIsAmerica(primaryAddressCountry ?? '');
      const isModifyingEntity = isModifyingEntitySelector(state);

      const removeProration = shouldRemoveProrationSelector(state);
      if (isModifyingEntity?.isModifying) await useMultiEntity(isModifyingEntity?.legalEntity as string);

      const isActiveFcmaRenewal = isActiveFcmaRenewalSelector(state);

      const savePaymentOptions: boolean = UserUtils.conditionalFunction(
        paymentMethod === Invoices.PaymentOptions.PAYPAL,
        savePaypal,
        saveCreditCardForFuture
      );

      //  Deprovisioning exam credit logic for users either switching their learning pathway (ie: PQ => FLP, or L7 => PQ), or downgrading their FLP tier to FLP Core //

      const learningPathwayInCart = getLearningPathwayProductInCart(state);
      const currentLearningPathway = learningPathwaySelector(state) || undefined;
      // The 'Core' FLP tier gives no credits, so if a user is switching during their renewal to Core, their existing FLP credits should be removed
      const isFLPTypeDowngradedToCore = isFLPTierBeingChangedToCore(state);
      const shouldExamCreditsBeDeprovisioned: boolean =
        // Checks that:
        // 1) there is a membership product in cart
        // 2) that there is a membership pathway change that should cause de-provisioning
        !!learningPathwayInCart && (learningPathwayInCart !== currentLearningPathway || isFLPTypeDowngradedToCore);

      const productsListData = productsListDataLineItemsSelector(state);
      const examCreditProducts = productsListData?.filter(data => data.productType === Product.ProductType.EXAM_CREDIT);
      const currentMembershipWithSku = currentMembershipProduct(state);
      const personAccountData = personAccountDataSelector(state);

      const deprovisioningExamCreditDetails = {
        personAccountData,
        currentMembershipWithSku,
        currentLearningPathway,
        examCreditProducts,
      };

      const zuoraOrderParams: Orders.OrderQueryDetails = {
        paymentMethodId: paymentMethodId ?? '', // no payment options do not have a paymentMethodId
        billingAddress,
        shippingAddress,
        paymentOption,
        sfAccountId,
        membershipAutoRenewEnabled,
        activeMembershipSubscription,
        isCenterMembershipRenewal,
        attestationTexts,
        isCenterMembershipJourney,
        membershipProratedPrice,
        optForAutoRenewProducts,
        learningPathway,
        qualificationLevelStatus,
        isCIMA,
        isRenewalSeasonOverride,
        renewalDateOverride,
        isAchPayment,
        paymentMethod,
        isFLPUpgrade,
        activeFLPSubscription,
        isFlpSwitchOrUpgrade,
        membershipRefund,
        isUserFBUpgradingMembership,
        isClickedMembershipUpgrade,
        isEPAsCompleted,
        hasItemWithThreeMonthRuleProration,
        isPERCompleted,
        claimGiftAid,
        isL7CandidateOrAffiliateCoreUpgrade,
        isCimaApprenticeLapsedRenewal,
        isApprenticeCandidateForRenewal,
        isApprenticeCimaRegularLapsedRenewal,
        isApprenticeCimaAffiliateLapsedRenewal,
        isApprenticeCimaResignedRenewal,
        isApprenticeRegularForRenewal,
        isApprenticeAffiliateForRenewal,
        isOrderPaidByFirm,
        useVitalSourcePriceCorrection,
        cimaMembershipTermIsTenYears,
        poNumber,
        removeProration,
        isActiveFcmaRenewal,
        isCreditCardSaved: FirmAdmin.areAllTruthy(!noPaymentNeeded, isSelectedCardSaved),
        saveCreditCard: FirmAdmin.areAllTruthy(!noPaymentNeeded, savePaymentOptions),
        hasAutoPay: FirmAdmin.areAllTruthy(!noPaymentNeeded, hasDefaultPaymentMethod),
        ...(isCenterMembershipJourney && {
          centerMembershipParams: {
            adminAccountDetail,
            organizationAccount,
            applicationPartId: UserUtils.conditionalFunction(
              applicationObject.applicationPart?.id,
              applicationObject.applicationPart?.id,
              ''
            ),
            firmMembershipType: AdminUtils.getFirmMembershipTypeForMemberhipSF(productSlug, isPCAOBOrganization),
            productSlug,
          },
        }),
        isSectionsCredentialsRenewal: FirmAdmin.hasTruthyValue(
          sectionsCredentialsRenewal.isTriggered,
          isMipCredentialRenewal,
          clickedSectionRenewalEvent
        ),
        isFlpRenewal: isFlpProductRenewal,
        ...(hasFLPProduct && { primaryAddressCountry }),
        isCimaEntityInvite:
          Boolean(inviteData?.inviteId) && // if firm billing invite
          hasExistingZuoraPurchase && // if there's an existing purchase
          inviteData?.organization?.organizationCapabilities?.type !== Salesforce.LegalEntity.ASSOCIATION && // check if coming from cima org
          isPrimaryAddressAmericas, // check if americas
        isAicpaEntityInvite:
          Boolean(inviteData?.inviteId) && // if firm billing invite
          hasExistingZuoraPurchase && // if there's an existing purchase
          inviteData?.organization?.organizationCapabilities?.type === Salesforce.LegalEntity.ASSOCIATION && // check if coming from aicpa org
          !isPrimaryAddressAmericas, // check if not americas
        isInLatestCycle,
      };

      const isCimaMipJourney = isCimaMipJourneySelector(state);
      const application = applicationSelector(state);

      if (isCimaMipJourney) {
        dispatch(
          updateApplication({
            ...application,
            applicationProgress: User.MembershipApplicationStages.APPLICATION,
          })
        );
      }

      return request(CREATE_ZUORA_ORDER, zuoraOrderParams)
        .then(res => {
          setTimeout(() => {
            dispatch(toggleModalPaymentProcessingOpen());
            dispatch(setPaymentTechCodeError(''));
            // HANDLE ZUORA RESPONSE
            createZuoraOrderHelper.handleZuoraResponse(
              res,
              learningPathway,
              isCheckPayment,
              cartLineItems,
              dispatch,
              shouldExamCreditsBeDeprovisioned ? { ...deprovisioningExamCreditDetails } : undefined
            );
          }, getTimeoutDiff());
          return res;
        })
        .catch((error: Error) => {
          setTimeout(() => {
            dispatch(toggleModalPaymentProcessingOpen());
            dispatch(goToOrderErrorPage({ createOrderStatusUnknown: true }));
          }, getTimeoutDiff());
        });
    }
);

export const updateZuoraAccount: any = createAction(
  'checkout/UPDATE_ZUORA_ACCOUNT',
  (defaultBillingAddress: State.Address) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    return request(UPDATE_ZUORA_ACCOUNT, {
      accountData: {
        billToContact: {
          address1: defaultBillingAddress.addressLine1,
          address2: defaultBillingAddress.addressLine2,
          city: defaultBillingAddress.city,
          state: defaultBillingAddress.state,
          zipCode: defaultBillingAddress.zipCode,
          country: defaultBillingAddress.country,
          firstName: defaultBillingAddress.firstName || userFirstNameSelector(state),
          lastName: defaultBillingAddress.lastName || userLastNameSelector(state),
          billToCompanyName__c: defaultBillingAddress.company || '',
        },
      },
    }).then(() => defaultBillingAddress);
  }
);

export const getHostedPageSignature: any = createAction(
  'checkout/GET_HOSTED_PAGE_SIGNATURE',
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const isModifyingEntity = isModifyingEntitySelector(state);

    if (isModifyingEntity?.isModifying) await useMultiEntity(isModifyingEntity?.legalEntity as string);

    return request(GET_HOSTED_PAGE_SIGNATURE).then(response => response.getHostedPageSignature);
  }
);

export const resetZuoraAccount: any = createAction('checkout/RESET_ZUORA_PAYMENT_METHODS');

export const getMultiHostedPageSignature: any = createAction(
  'checkout/GET_MULTI_HOSTED_PAGE_SIGNATURE',
  ({ currency, entity, paymentMethod }: { currency: string; entity: Salesforce.LegalEntity; paymentMethod: string }) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      const payload = {
        currency,
        entity,
        paymentMethod,
      };
      const state: State.Root = getState();
      const isOrgInvoiceCheckout = isOrgInvoiceCheckoutSelector(state);
      const isAdminInvoicesPayment = isAdminInvoicesPaymentJourneySelector(state);
      const isPageFirmBillingInvoiceCheckout = isPageFirmBillingInvoiceCheckoutSelector(state);
      const b2bAdminLegalEntity = legalEntitySelector(state);
      const selectedOrganization = selectedOrganizationSelector(state);
      const firmEntity = selectedOrganization?.organizationCapabilities?.type;
      const selectedInvoice = selectedInvoicesSelector(state);
      const existingEntity = isAdminInvoicesPayment
        ? selectedInvoice[0]?.legalEntity || b2bAdminLegalEntity
        : isPageFirmBillingInvoiceCheckout
        ? firmEntity?.toLowerCase()
        : isOrgInvoiceCheckout
        ? b2bAdminLegalEntity
        : entity.toLowerCase();

      const customHeaders = {
        existingEntity,
      };

      // If Eckoh, do not pass the entity to customerHeaders during B2C checkout. Entity will be fetched using fetchLegalEntity
      if (paymentMethod === Invoices.PaymentOptions.ECKOH) {
        // If organization invoice checkout (B2B) , Admin Invoice payment, Firm Billing pay invoice include the entity in customerHeaders since entity is preselected when paying invoice
        if (isOrgInvoiceCheckout || isAdminInvoicesPayment || isPageFirmBillingInvoiceCheckout) {
          updateContext(customHeaders);
        }
      } else {
        updateContext(customHeaders);
      }

      return request(GET_MULTI_HOSTED_PAGE_SIGNATURE, payload).then(response => response.getMultiHostedPageSignature);
    }
);

export const createMandate: any = createAction(
  'checkout/CREATE_MANDATE',
  ({ mandateText, paymentType }: { mandateText: string; paymentType: Orders.PaymentMandateType }) =>
    async (dispatch: Dispatch) => {
      dispatch(loading());
      const payload = {
        mandateText,
        paymentType,
      };
      const response = await request(CREATE_MANDATE, payload).then(res => res.createMandate);
      return response;
    }
);

export const updateMandate: any = createAction(
  'checkout/UPDATE_MANDATE',
  ({
    mandateStatus,
    paymentType,
  }: {
    mandateStatus: Orders.PaymentMandateStatus;
    paymentType: Orders.PaymentMandateType;
  }) => {
    const payload = {
      mandateStatus,
      paymentType,
    };
    return request(UPDATE_MANDATE, payload).then(response => response.updateMandate);
  }
);

export const deleteMandate: any = createAction(
  'checkout/DELETE_MANDATE',
  ({ paymentType }: { paymentType: Orders.PaymentMandateType }) => {
    const payload = {
      paymentType,
    };
    return request(DELETE_MANDATE, payload).then(response => response.deleteMandate);
  }
);

const isAddressProcessedChecker = async (dispatch: Dispatch, state: State.Root) => {
  const isShippingAddressValid: boolean | null = isShippingAddressValidSelector(state);
  const isBillingAddressValid: boolean | null = isBillingAddressValidSelector(state);
  const savedAddressChecked: boolean = savedShippingAddressCheckedSelector(state);
  const savedBillingAddressChecked: boolean = savedBillingAddressCheckedSelector(state);

  const address: State.Address = baseShippingAddressSelector(state);
  const fullShippingAddress: Salesforce.ContactPointAddress = shippingAddressSelector(state);
  const billingAddress: State.Address = baseBillingAddressSelector(state);

  const response = await dispatch(getCompleteAddressFormValidation(address, billingAddress));

  if (!response) return isShippingAddressValid && isBillingAddressValid;

  const responseBase = response.payload?.smartystreetsValidation;
  const shippingScenario = await parseAddressResponses(
    (zuoraAddress, sfAddress) => {
      dispatch(setShippingAddress(zuoraAddress));
      dispatch(addSalesforceAddress(sfAddress, fullShippingAddress.isPrimary, fullShippingAddress.addressType));
    },
    responseBase.shippingAddress,
    responseBase.validationNeeded,
    savedAddressChecked
  );
  const billingScenario = await parseAddressResponses(
    newAddress => dispatch(setBillingAddress(newAddress)),
    responseBase.billingAddress,
    responseBase.billingValidationNeeded,
    savedBillingAddressChecked
  );

  const { allowShippingAddressValidationBypass, allowBillingAddressValidationBypass } = getValidationBypassFlags(
    address,
    billingAddress,
    state
  );

  const isShippingInvalid = shippingScenario === SmartyStreets.ValidationCheckoutToggle.INVALID;
  const isBillingInvalid = billingScenario === SmartyStreets.ValidationCheckoutToggle.INVALID;

  if (
    shouldDisplayInvalidAddressModal(
      isShippingInvalid,
      isBillingInvalid,
      allowShippingAddressValidationBypass,
      allowBillingAddressValidationBypass
    )
  ) {
    dispatch(toggleModalInvalidAddressOpen());
  } else if (shouldDisplaySuggestedAddressModal(shippingScenario, billingScenario)) {
    dispatch(
      toggleModalAddressValidationOpen({
        isAddressValidationModalOpen: true,
        allowUserToBypassAddressValidation: {
          allowShippingAddressValidationBypass,
          allowBillingAddressValidationBypass,
        },
      })
    );
  }

  handleSecondaryAddress(dispatch, shippingScenario, billingScenario);

  return response.payload?.isShippingAddressValid && response.payload?.isBillingAddressValid;
};

export const goToPaymentAction: any = createAction(
  'checkout/GO_TO_PAYMENT',
  (isFreeCart = false) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      dispatch(loading());
      const state: State.Root = getState();

      const isImpersonate: boolean = Boolean(isAuthSelector(state));
      const isAdminPortal: boolean = isAdminPortalSelector(state);
      const useOfflinePaymentOptions: boolean = getFeatureToggleByKeySelector(state, USE_OFFLINE_PAYMENT_OPTIONS);
      const hasFLPProduct = hasFlpProductSelector(state);
      const memberships = customerMembershipsSelector(state);
      const hasExistingZuoraPurchase = hasExistingZuoraPurchaseSelector(state);
      const membershipInviteData = membershipInviteDataSelector(state);
      const isAddressProcessed = await isAddressProcessedChecker(dispatch, state);
      const cartSelectedMembership = membershipLineItemSelector(state);

      if (isAddressProcessed) {
        if (!hasExistingZuoraPurchase && !membershipInviteData?.inviteId) {
          updateContext({ existingEntity: 'RESET' }); // Reset entity as users can go back and forth changing their products in cart.
        }
        let existingEntity: ZuoraTypes.LegalEntity;
        if (hasFLPProduct) {
          if (memberships?.length) {
            existingEntity =
              memberships[0]?.membershipBody === MembershipTypes.MembershipBody.AICPA
                ? ZuoraTypes.LegalEntity.ASSOCIATION
                : ZuoraTypes.LegalEntity.CIMA;
          } else {
            const shippingAddress: State.Address = shippingAddressSelector(state);
            const primaryAddress = shippingAddress.isPrimary
              ? shippingAddress
              : allAddressesSelector(state)?.find(addr => addr.isPrimary);
            const isPrimaryAddressAmericas =
              CountriesList.find(country => country.text === ConvertFromISOAlpha3ToText(primaryAddress?.country || ''))
                ?.isAmerica || false;
            const cartSelectedMembershipBody = cartSelectedMembership?.variant?.attributes?.membershipBody?.key;
            const cartSelectedMembershipEntity = UserUtils.conditionalFunction(
              cartSelectedMembershipBody,
              UserUtils.conditionalFunction(
                cartSelectedMembershipBody === Product.ProductMembershipBody.AICPA,
                ZuoraTypes.LegalEntity.ASSOCIATION,
                ZuoraTypes.LegalEntity.CIMA
              ),
              false
            );
            existingEntity = UserUtils.conditionalFunction(
              cartSelectedMembershipEntity,
              cartSelectedMembershipEntity,
              UserUtils.conditionalFunction(
                isPrimaryAddressAmericas,
                ZuoraTypes.LegalEntity.ASSOCIATION,
                ZuoraTypes.LegalEntity.CIMA
              )
            );
          }

          // Remove donation cart items that are not of correct entity
          const cartDetailItems = cartDetailItemsSelector(state);
          if (cartDetailItems.length) {
            const itemsToBeRemoved = cartDetailItems
              .filter(
                item =>
                  item.productType === Product.ProductType.CONTRIBUTION &&
                  item.membershipBody !== ZuoraTypes.MembershipBodyLegalEntityMap[existingEntity]
              )
              ?.map(productItem => productItem.lineItemId);

            if (itemsToBeRemoved.length) {
              await dispatch(removeCartItems(itemsToBeRemoved));
            }
          }
          // firm billing invite
          const isModifyingEntity = isModifyingEntitySelector(state);
          updateContext({
            existingEntity: isModifyingEntity?.isModifying ? isModifyingEntity?.legalEntity : existingEntity,
          });
        }
        if (isFreeCart) {
          return dispatch(createZuoraOrder());
        }
        await dispatch(proceedToOrderPreview());
      }
      return { isAddressProcessed, isImpersonate: isAdminPortal && isImpersonate && useOfflinePaymentOptions };
    }
);

export const proceedToOrderPreview: any = createAction(
  'checkout/ORDER_PREVIEW',
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(loading());
    const state: State.Root = getState();
    const isDefaultBillingAddressExist: boolean = isDefaultBillingAddressExistSelector(state);
    const hasMembershipProduct = hasMembershipProductSelector(state);
    const hasSectionProduct = hasSectionProductSelector(state);
    const hasCredentialProduct = hasCredentialProductSelector(state);
    const hasCentersProduct = hasCentersProductSelector(state);
    const hasFLPProduct = hasFlpProductSelector(state);
    const isFLPSwitchOrUpgrade = isFLPSwitchOrUpgradeSelector(state);
    if (!isDefaultBillingAddressExist) {
      const sameShippingAddressForBilling: boolean = sameShippingAddressForBillingSelector(state);
      const billingAddress: State.Address = sameShippingAddressForBilling
        ? shippingAddressSelector(state)
        : billingAddressSelector(state);

      await dispatch(updateZuoraAccount(billingAddress));
    }

    const orderPreviewErrorHandler = () => {
      dispatch(goToOrderErrorPage());
      throw new Error('get order preview failed');
    };

    const orderPreviewRes = await dispatch(getOrderPreview());
    if (!orderPreviewRes.payload.success) {
      orderPreviewErrorHandler();
    }
    if (hasMembershipProduct || hasSectionProduct || hasCredentialProduct || hasCentersProduct) {
      const membershipPreviewRes = await dispatch(getMembershipOrderPreview());
      if (!membershipPreviewRes.payload.success) {
        orderPreviewErrorHandler();
      }
      // Check if type change. Need to preview refund
      const previousMembership = activeMembershipSubscriptionSelector(state);
      const newMembership = selectedMembershipSelector(state);

      const isUserAicpaRegularMember =
        previousMembership?.variant?.attributes?.membershipBody?.key === Product.ProductMembershipBody.AICPA &&
        previousMembership?.variant?.attributes?.membershipKey?.key === User.MembershipTypes.REGULAR;
      if (
        previousMembership &&
        previousMembership?.variant?.sku !== newMembership?.variant?.sku &&
        (hasMembershipProduct || hasFLPProduct) &&
        !(isUserAicpaRegularMember && hasFLPProduct) && // Don't call refund if AICPA Regular and has FLP Product
        !isFLPSwitchOrUpgrade
      ) {
        // FLP product should be current day refund to charge correctly.
        const startDate = hasFLPProduct ? moment().format('YYYY-MM-DD') : previousMembership.zuoraTermEndDate;
        const refundMembershipRes = await dispatch(
          getRefundMembershipSubscriptionPreview(previousMembership?.subscriptionNumber, startDate)
        );
        if (refundMembershipRes.payload?.refundAmount !== 0 && !refundMembershipRes.payload.refundAmount) {
          orderPreviewErrorHandler();
        }
      }
    }
  }
);

export const getRefundMembershipSubscriptionPreview: any = createAction(
  'checkout/REFUND_MEMBERSHIP_SUBSCRIPTION_PREVIEW',
  (subscriptionNumber: string, subscriptionStartDate: string) => {
    const payload = {
      subscriptionStartDate,
      subscriptionNumber,
    };
    return request(PREVIEW_CANCEL_MEMBERSHIP_ORDER, payload).then(response => response.previewCancelMembershipOrder);
  }
);

export const getAddressAutocompleteItems: any = createAction(
  'checkout/GET_ADDRESS_AUTOCOMPLETE_ITEMS',
  (prefix: string) => (dispatch: Dispatch) => {
    dispatch(toggleIsLoadingAutocompleteItems());
    return request(GET_ADDRESS_AUTOCOMPLETE_ITEMS, { prefix }).then(response => response.getAddressAutocomplete);
  }
);

export const getCompleteAddressFormValidation: any = createAction(
  'checkout/GET_COMPLETE_ADDRESS_VALIDATION',
  async () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const shippingAddress = checkoutShippingAddressSelector(state);
    const billingAddress = checkoutBillingAddressSelector(state);
    return getSmartyStreetsValidation(shippingAddress, billingAddress);
  }
);

export const getOrderPreview: any = createAction(
  'checkout/GET_ORDER_PREVIEW',
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const { shippingAddress, billingAddress } = getZuoraAddresses(state);
    const activeMembershipSubscription =
      activeMembershipSubscriptionSelector(state) || activeFlpSubscriptionSelector(state);
    const activeFLPSubscription = activeFlpSubscriptionSelector(state);
    const isInLatestCycle = isInLatestCycleSelector(state);
    const flpLineItem = flpLineItemSelector(state);
    const sectionsCredentialsRenewal = sectionsCredentialsRenewalSelector(state);
    const isCenterMembershipRenewal = isCenterMembershipRenewalSelector(state);
    const organizationAccount = centerMembershipPackageOrganizationSelector(state);
    const adminAccountDetail = centerMembershipApplicationAdminDetailSelector(state);
    const isCenterMembershipJourney = isCenterMembershipJourneySelector(state);
    const applicationObject = centerMembershipApplicationObjectSelector(state);
    const productSku = userMembershipTypeSelector(state);
    const { isPCAOBOrganization } = centerMembershipPackageOrganizationSelector(state);
    const isRenewalSeasonOverride = getRenewalSeasonOverrideSelector(state);
    const cartLineItems = lineItemsSelector(state);
    const isRenewal = isRenewalSelector(state);
    const renewalDateOverride = getRenewalDateOverrideSelector(state);
    const isFlpSwitch = isFLPSwitchSelector(state);
    const isFlpUpgrade = isFLPUpgradeSelector(state);
    const isFlpRenewal = isFlpRenewalSelector(state);
    const isFlpSwitchOrUpgrade = isFlpSwitch || isFlpUpgrade;
    const primaryAddressCountry = primaryAddressSelector(state)?.country;
    const hasFLPProduct = hasFlpProductSelector(state);
    const currentJourneyLearningPathway = currentJourneyLearningPathwaySelector(state);
    const credentialData = customerCredentialsSelector(state);
    // apply logic of clicked Membership upgrade for AICPA and CIMA memberships to prevent extending whenever upgrading
    const isClickedMembershipUpgrade = clickedMembershipUpgradeSelector(state);
    const isEPA1Completed = isEPA1CompletedSelector(state);
    const isEPA2Completed = isEPA2CompletedSelector(state);
    const isEPAsCompleted = isEPA1Completed && isEPA2Completed;
    const cimaMembershipTermIsTenYears = cimaMembershipTermIsTenYearsSelector(state);
    const isMipRenewalSeason = isMipRenewalSeasonSelector(state);
    const isMipCredentialRenewal = hasMipCredentialProductSelector(state) && isMipRenewalSeason;
    const hasItemWithThreeMonthRuleProration = hasItemWithThreeMonthRuleProrationSelector(state);
    const isPERCompleted =
      practicalExperienceRequirementStatusSelector(state) === MembershipTypes.PERPortfolioStatus.COMPLETED;
    const claimGiftAid = claimGiftAidSelector(state);
    const clickedSectionRenewalEvent = clickedSectionRenewalSelector(state);
    // firm billing
    const isModifyingEntity = isModifyingEntitySelector(state);
    if (isModifyingEntity?.isModifying) await useMultiEntity(isModifyingEntity?.legalEntity as string);

    /**
     * Existing sections for renewal will not be prorated
     * New sections will be prorated
     */
    const override =
      !sectionsCredentialsRenewal.isTriggered && (isRenewal || isMipCredentialRenewal)
        ? forceNonProrationPrices(cartLineItems, credentialData, {
            isSectionsCredentialsRenewal: true,
          })
        : {};

    const isRenewalButtonClicked = clickedCimaMembershipRenewalSelector(state);
    const isFlpProductRenewal = OrderUtils.checkFlpProductRenewal(
      isFlpRenewal,
      activeFLPSubscription,
      flpLineItem,
      isRenewalButtonClicked
    );
    const currentMembershipEntity = getUserMembershipEntity(state);
    const removeProration = shouldRemoveProrationSelector(state);
    const isActiveFcmaRenewal = isActiveFcmaRenewalSelector(state);

    if (shippingAddress?.state) {
      return request(GET_ORDER_PREVIEW, {
        shippingAddress,
        billingAddress,
        activeMembershipSubscription,
        // For sections renewals made in the "My Memberships and benefits" this can't be triggered
        isSectionsCredentialsRenewal:
          sectionsCredentialsRenewal.isTriggered || isMipCredentialRenewal || clickedSectionRenewalEvent,
        isCenterMembershipRenewal,
        isRenewalSeasonOverride,
        renewalDateOverride,
        ...override,
        ...(isCenterMembershipJourney && {
          centerMembershipParams: {
            adminAccountDetail,
            organizationAccount,
            applicationPartId: applicationObject.applicationPart?.id || '',
            firmMembershipType: AdminUtils.getFirmMembershipTypeForMemberhipSF(productSku, isPCAOBOrganization),
          },
        }),
        isFlpSwitchOrUpgrade,
        isFlpRenewal: isFlpProductRenewal,
        ...(hasFLPProduct && { primaryAddressCountry }),
        currentJourneyLearningPathway,
        isClickedMembershipUpgrade,
        isEPAsCompleted,
        hasItemWithThreeMonthRuleProration,
        isPERCompleted,
        claimGiftAid,
        currentMembershipEntity,
        cimaMembershipTermIsTenYears,
        removeProration,
        isActiveFcmaRenewal,
        isInLatestCycle,
      }).then(async response => {
        // store in redux state after purchase.
        if (!!response.getOrderPreview.legalEntity) {
          dispatch(setLegalEntity(response.getOrderPreview.legalEntity.toLowerCase()));

          if (!isFlpSwitchOrUpgrade) {
            // update graphql context to get update existing entity.
            const customHeaders = {
              existingEntity: response.getOrderPreview.legalEntity.toLowerCase(),
            };
            updateContext(customHeaders);
          }
        }
        return response.getOrderPreview;
      });
    }
  }
);

export const getMembershipOrderPreview: any = createAction(
  'checkout/GET_MEMBERSHIP_ORDER_PREVIEW',
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const activeMembershipSubscription =
      activeMembershipSubscriptionSelector(state) || activeFlpSubscriptionSelector(state);
    const { shippingAddress, billingAddress } = getZuoraAddresses(state);
    const isInLatestCycle = isInLatestCycleSelector(state);
    const organizationAccount = centerMembershipPackageOrganizationSelector(state);
    const adminAccountDetail = centerMembershipApplicationAdminDetailSelector(state);
    const isCenterMembershipJourney = isCenterMembershipJourneySelector(state);
    const applicationObject = centerMembershipApplicationObjectSelector(state);
    const productSku = userMembershipTypeSelector(state);
    const { isPCAOBOrganization } = centerMembershipPackageOrganizationSelector(state);
    const isCenterMembershipRenewal = isCenterMembershipRenewalSelector(state);
    const isRenewalSeasonOverride = getRenewalSeasonOverrideSelector(state);
    const renewalDateOverride = getRenewalDateOverrideSelector(state);
    const isFlpRenewal = isFlpRenewalSelector(state);
    const isFlpSwitch = isFLPSwitchSelector(state);
    const isFlpUpgrade = isFLPUpgradeSelector(state);
    const isFlpSwitchOrUpgrade = isFlpSwitch || isFlpUpgrade;
    const primaryAddressCountry = primaryAddressSelector(state)?.country;
    const hasFLPProduct = hasFlpProductSelector(state);
    const isEPA1Completed = isEPA1CompletedSelector(state);
    const isEPA2Completed = isEPA2CompletedSelector(state);
    const isEPAsCompleted = isEPA1Completed && isEPA2Completed;
    const cimaMembershipTermIsTenYears = cimaMembershipTermIsTenYearsSelector(state);
    const removeProration = shouldRemoveProrationSelector(state);
    const isPERCompleted =
      practicalExperienceRequirementStatusSelector(state) === MembershipTypes.PERPortfolioStatus.COMPLETED;
    const claimGiftAid = claimGiftAidSelector(state);
    // apply logic of clicked Membership upgrade for AICPA/CIMA memberships to prevent extending whenever upgrading
    const isClickedMembershipUpgrade = clickedMembershipUpgradeSelector(state);
    const sectionsCredentialsRenewal = sectionsCredentialsRenewalSelector(state);
    const isMipRenewalSeason = isMipRenewalSeasonSelector(state);
    const isMipCredentialRenewal = hasMipCredentialProductSelector(state) && isMipRenewalSeason;
    const clickedSectionRenewalEvent = clickedSectionRenewalSelector(state);
    const activeFLPSubscription = activeFlpSubscriptionSelector(state);
    const flpLineItem = flpLineItemSelector(state);

    const isRenewalButtonClicked = clickedCimaMembershipRenewalSelector(state);
    const isFlpProductRenewal = OrderUtils.checkFlpProductRenewal(
      isFlpRenewal,
      activeFLPSubscription,
      flpLineItem,
      isRenewalButtonClicked
    );
    const currentMembershipEntity = currentMembershipEntitySelector(state);
    const hasItemWithThreeMonthRuleProration = hasItemWithThreeMonthRuleProrationSelector(state);
    const isModifyingEntity = isModifyingEntitySelector(state);
    if (isModifyingEntity?.isModifying) await useMultiEntity(isModifyingEntity?.legalEntity as string);

    return request(GET_ORDER_PREVIEW, {
      shippingAddress,
      billingAddress,
      membershipProductOnly: true,
      activeMembershipSubscription,
      isCenterMembershipRenewal,
      isRenewalSeasonOverride,
      renewalDateOverride,
      ...(isCenterMembershipJourney && {
        centerMembershipParams: {
          adminAccountDetail,
          organizationAccount,
          applicationPartId: applicationObject.applicationPart?.id || '',
          firmMembershipType: AdminUtils.getFirmMembershipTypeForMemberhipSF(productSku, isPCAOBOrganization),
        },
      }),
      isSectionsCredentialsRenewal:
        sectionsCredentialsRenewal.isTriggered || isMipCredentialRenewal || clickedSectionRenewalEvent,
      isFlpSwitchOrUpgrade,
      isFlpRenewal: isFlpProductRenewal,
      ...(hasFLPProduct && { primaryAddressCountry }),
      isClickedMembershipUpgrade,
      isEPAsCompleted,
      isPERCompleted,
      claimGiftAid,
      currentMembershipEntity,
      hasItemWithThreeMonthRuleProration,
      cimaMembershipTermIsTenYears,
      removeProration,
      isInLatestCycle,
    }).then(response => response.getOrderPreview);
  }
);

export const goToOrderErrorPage: any = createAction(
  'checkout/GO_TO_ERROR_PAGE',
  ({
      commerceToolsFailed = false,
      createOrderStatusUnknown = false,
      error,
    }: {
      commerceToolsFailed?: boolean;
      createOrderStatusUnknown?: boolean;
      error?: Orders.OrderError;
    } = emptyObject) =>
    (dispatch: Dispatch, getState: () => State.Root): void => {
      const state: State.Root = getState();
      const cartId: string | undefined = cartIdSelector(state) || undefined;

      dispatch(
        push({
          pathname: generatePath(getPath(Routes.ORDER_ERROR_PAGE), { cartId }),
          state: { commerceToolsFailed, createOrderStatusUnknown, error },
        })
      );
    }
);

export const clearAddressAutocompleteItems: any = createAction('checkout/CLEAR_ADDRESS_AUTOCOMPLETE_ITEMS');

export const resetModule: any = createAction('checkout/RESET_MODULE');

export const setDefaultPaymentMethod: any = createAction(
  'checkout/SET_DEFAULT_PAYMENT_METHOD',
  (creditCard?: State.CreditCard, notify: boolean = true) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      const state: State.Root = getState();
      const card: State.CreditCard | null = creditCard || cardBeingProcessedSelector(state);

      const entity: string = selectedPaymentMethodEntity(state) as string;
      const { cima, association }: State.PaymentMethods = otherPaymentMethodSelector(state);
      const userCurrency = selectedCurrencySelector(state);
      const paymentMethodId = card?.id;

      let setDefaultResponse;

      if (entity) {
        setDefaultResponse = await request(UPDATE_PAYMENT_INFORMATION_BY_LEGALENTITY, {
          zuoraId: entity === 'CIMA' ? cima.zuoraId : association.zuoraId,
          legalEntity: entity,
          paymentInfo: {
            defaultPaymentMethodId: paymentMethodId,
          },
          cardType: card?.cardType,
          currency: userCurrency,
        }).then(response => {
          if (!response.updateZuoraAccountPaymentInformationByLegalEntity.success) {
            throw new Error('set default payment method failed');
          }
          return { paymentMethodId };
        });
      } else {
        const cardNumberLastFour: string | undefined = notify ? card?.cardNumber.slice(-4) : undefined;
        setDefaultResponse = await request(UPDATE_PAYMENT_INFORMATION, {
          paymentInfo: {
            DefaultPaymentMethodId: paymentMethodId,
            cardNumberLastFour,
          },
        }).then(response => {
          if (!response.updateZuoraAccountPaymentInformation.Success) {
            throw new Error('set default payment method failed');
          }
          return { paymentMethodId };
        });
      }

      return setDefaultResponse;
    }
);

export const sendPaymentMethodEmail: any = createAction(
  'checkout/SEND_PREFERRED_PAYMENT_METHOD_EMAIL',
  (transactionName: string, creditCard?: State.CreditCard, notify: boolean = true) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      const state = getState();
      const card: State.CreditCard | null = creditCard || cardBeingProcessedSelector(state);
      const cardNumberLastTwo: string | undefined = notify ? card?.cardNumber.slice(-2) : undefined;

      const paymentMethodEmail: Orders.PaymentMethodEmail = {
        transactionName,
        obscuredCardNumber: `XXXX-XXXX-XX${cardNumberLastTwo}`,
      };

      return request(MUTATE_PAYMENT_METHOD_EMAIL, { paymentMethodEmail })
        .then((response: any) => {
          if (response?.sendPaymentMethodEmail) {
            return { ...response, success: true };
          }
          return { success: false };
        })
        .catch(err => {
          return { success: false };
        });
    }
);

export const removePaymentMethod: any = createAction(
  'checkout/REMOVE_PAYMENT_METHOD',
  (creditCard?: State.CreditCard) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const card: State.CreditCard | null = creditCard || cardBeingProcessedSelector(state);
    const hasActiveSubscriptions: boolean = hasUserActiveSubscriptionsSelector(state);
    const hasActiveStandingOrders: boolean = hasUserActiveStandingOrderSelector(state);
    const hasUserPhysicalAwaitingOrders: boolean = hasUserAwaitingShipmentOrderSelector(state);
    const paymentMethodId = card?.id;
    return request(REMOVE_PAYMENT_METHOD, {
      paymentMethodId,
      isDefault: card?.defaultPaymentMethod,
      cardNumberLastFour: card?.cardNumber.slice(-4),
      hasActiveSubscriptions,
      hasActiveStandingOrders,
      hasUserPhysicalAwaitingOrders,
    }).then(response => {
      if (!response.deletePaymentMethod.success) {
        throw new Error('remove payment method failed');
      }
      return { paymentMethodId };
    });
  }
);

export const setSavedCardUsed: any = createAction('checkout/SET_SAVED_CARD_USED');
export const setSecondaryAddressNeeded: any = createAction('checkout/SET_SECONDARY_ADDRESS_NEEDED');
export const setSecondaryBillingAddressNeeded: any = createAction('checkout/SET_SECONDARY_BILLING_ADDRESS_NEEDED');

export const sendCreditCardMail: any = createAction(
  'checkout/SEND_CREDIT_CARD_MAIL',
  (definitionKey: string, creditCard?: State.CreditCard) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const card: State.CreditCard | null = creditCard || cardBeingProcessedSelector(state);

    return request(SEND_CREDIT_CARD_EMAIL, {
      definitionKey,
      cardNumberLastFour: card?.cardNumber.slice(-4),
    }).then(response => response.sendCreditCardMail);
  }
);

export const toggleSubscriptionRenewal: any = createAction(
  'checkout/TOGGLE_SUBSCRIPTION_RENEWAL',
  (
      subscriptionNumber: string,
      autoRenew: boolean,
      productName: string,
      sku?: string | undefined,
      fcmaSubscriptionNumber?: string | undefined
    ) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      return request(SUBSCRIPTION_TOGGLE, {
        subscriptionNumber,
        autoRenew,
        productName,
        sku,
        fcmaSubscriptionNumber,
      }).then(response => {
        const { subscriptionToggle } = response;
        if (!subscriptionToggle.success) {
          dispatch(setSubscriptionRenewalToggleLoading(false));
          throw new Error('Toggle subscription renewal method failed');
        }
        return subscriptionToggle?.success || false;
      });
    }
);

export const getReceiptLoading: any = createAction('checkout/GET_RECEIPT_LOADING');

export const getReceipt: any = createAction(
  'checkout/GET_RECEIPT',
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(getReceiptLoading());
    return dispatch(getZuoraAccount()).then(() => {
      const state: State.Root = getState();
      const match: any = createMatchSelector(getPath(Routes.PROFILE_RECEIPT_CHECKOUT))(state as State.Root);
      const { invoiceId, legalEntity } = (match && match.params) || emptyObject;
      updateContext({ existingEntity: legalEntity });

      return request(GET_RECEIPT, { invoiceId, legalEntity }).then(response => response.getReceipt);
    });
  }
);

// Please note that invoice and receipt are the same
export const payInvoice: any = createAction(
  'checkout/MAKE_RECEIPT_PAYMENT',
  (paymentMethodId: string, saveCreditCard: boolean) => (dispatch: Dispatch, getState: () => State.Root) => {
    const startTime: number = Date.now();
    dispatch(toggleModalPaymentProcessingOpen());

    const getTimeoutDiff = (): number => {
      const endTime: number = Date.now();
      const diff: number = endTime - startTime;
      return diff > PAYMENT_PROCESSING_TIMEOUT ? 0 : PAYMENT_PROCESSING_TIMEOUT - diff;
    };
    const state: State.Root = getState();
    const receipt = getReceiptSelector(state);

    const payInvoiceParams: Invoices.PayInvoiceParams = {
      paymentMethodId,
      isB2B: false,
      saveCreditCard,
      amount: receipt?.amount,
      balance: receipt?.balance,
      invoiceId: receipt?.id,
      accountId: receipt?.accountId,
      invoiceNumber: receipt?.invoiceNumber,
      invoiceEntity: receipt?.legalEntity,
    };

    return request(MAKE_RECEIPT_PAYMENT, payInvoiceParams)
      .then(res => {
        setTimeout(() => {
          dispatch(toggleModalPaymentProcessingOpen());
          const zuoraSuccess: boolean =
            res.payInvoice.success && res.payInvoice.status === Invoices.PaymentStatus.PROCESSED;

          if (zuoraSuccess) {
            dispatch(resetModule());
            dispatch(
              push(
                generatePath(getPath(Routes.PROFILE_RECEIPT_PAYMENT_CONFIRMATION), {
                  invoiceId: res.payInvoice.invoiceId,
                })
              )
            );
          } else {
            dispatch(goToReceiptCheckoutErrorPage({ receiptCheckoutError: true }));
            Promise.reject('Error paying for receipt');
          }
        }, getTimeoutDiff());
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(toggleModalPaymentProcessingOpen());
          dispatch(goToReceiptCheckoutErrorPage({ receiptCheckoutError: true }));
        }, getTimeoutDiff());
      });
  }
);

export const getReceiptPaymentConfirmationResultLoading: any = createAction(
  'checkout/GET_RECEIPT_PAYMENT_CONFIRMATION_RESULT_LOADING'
);

export const getReceiptPaymentConfirmationResult: any = createAction(
  'checkout/GET_RECEIPT_PAYMENT_CONFIRMATION_RESULT',
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(getReceiptPaymentConfirmationResultLoading());
    const state: State.Root = getState();
    const match: any = createMatchSelector(getPath(Routes.PROFILE_RECEIPT_PAYMENT_CONFIRMATION))(state);
    const { invoiceId } = (match && match.params) || emptyObject;

    return request(GET_RECEIPT_PAYMENT_CONFIRMATION_DETAILS, { invoiceId }).then(
      response => response.getReceiptPaymentConfirmationDetails
    );
  }
);

export const resetReceiptPaymentConfirmationData: any = createAction(
  'checkout/RESET_RECEIPT_PAYMENT_CONFIRMATION_DATA'
);

export const goToReceiptCheckoutErrorPage: any = createAction(
  'checkout/RECEIPT_CHECKOUT_ERROR_PAGE',
  ({ receiptCheckoutError = false }: any = emptyObject) =>
    (dispatch: Dispatch, getState: () => State.Root): void => {
      const state: State.Root = getState();
      const match: any = createMatchSelector(getPath(Routes.PROFILE_RECEIPT_CHECKOUT))(state);
      const invoiceId = match?.params?.invoiceId || emptyObject;

      const path = invoiceId
        ? generatePath(getPath(Routes.PROFILE_RECEIPT_CHECKOUT_ERROR), { invoiceId })
        : generatePath(getPath(Routes.PROFILE_RECEIPT_CHECKOUT_ERROR));

      dispatch(
        push({
          pathname: path,
          state: { receiptCheckoutError },
        })
      );
    }
);

export const setPaymentTechCodeError: any = createAction('checkout/SET_PAYMENT_TECH_CODE_ERROR');

export const updateApplicationAndAttestation: any = createAction(
  'checkout/UPDATE_APPLICATION_AND_ATTESTATION',
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const application = applicationSelector(state);
    const centerMembershipApplication = centerMembershipApplicationObjectSelector(state);
    const hasCenterMembershipProduct = hasCentersProductSelector(state);
    const organizationAccount = centerMembershipPackageOrganizationSelector(state);
    const hasMembershipProduct = hasMembershipProductSelector(state);
    const hasSectionProduct = hasSectionProductSelector(state);
    const hasCredentialProduct = hasCredentialProductSelector(state);
    const hasFCMACredentialProduct = hasFcmaCredentialSelector(state);

    if (hasCenterMembershipProduct && !organizationAccount.underReview) {
      // if organization is under review means application need peer review before approval.
      await dispatch(
        updateApplication({
          ...centerMembershipApplication,
          applicationProgress: '',
          status: MembershipTypes.ApplicationStatusEnum.AUTO_APPROVED,
        })
      );
    }
    // only update application to approved when cart has membership and membership related products.
    if (hasMembershipProduct || hasSectionProduct || hasCredentialProduct || hasFCMACredentialProduct) {
      await dispatch(
        updateApplication({
          ...application,
          applicationProgress: '',
          status: MembershipTypes.ApplicationStatusEnum.APPROVED,
        })
      );
    }
  }
);

export const getPaymentsIdByAccountId: any = createAction(
  'checkout/GET_PAYMENTS_ID',
  (accountId: string) => (dispatch: Dispatch) => {
    dispatch(loading());
    return request(GET_PAYMENTS_ID, { accountId }).then(response => {
      return response.getPaymentsIdByAccountId;
    });
  }
);

export const setCimaDefaultPaymentMethod: any = createAction(
  'checkout/SET_CIMA_DEFAULT_PAYMENT_METHOD',
  (paymentMethodId: string, retainDefaultPaymentMethod: boolean = false, replacementCardType?: string) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      const state: State.Root = getState();
      const { cima, association }: State.PaymentMethods = otherPaymentMethodSelector(state);
      const entity: string = selectedPaymentMethodEntity(state)?.toLocaleLowerCase() as string;
      const userCurrency = selectedCurrencySelector(state);

      const cimaDefaultPaymentMethodId = cima.defaultPaymentMethodId;
      const associationPaymentMethodId = association.defaultPaymentMethodId;

      const updateResponse = await request(UPDATE_PAYMENT_INFORMATION_BY_LEGALENTITY, {
        // make default
        zuoraId: entity === Salesforce.LegalEntity.CIMA.toLocaleLowerCase() ? cima.zuoraId : association.zuoraId,
        legalEntity: entity,
        cardType: replacementCardType,
        currency: userCurrency,
        paymentInfo: {
          defaultPaymentMethodId: paymentMethodId,
        },
      }).then(response => response.updateZuoraAccountPaymentInformationByLegalEntity.success);

      if (updateResponse) {
        const defaultPaymentId =
          entity === Salesforce.LegalEntity.CIMA.toLocaleLowerCase()
            ? cimaDefaultPaymentMethodId
            : associationPaymentMethodId;
        if (Boolean(defaultPaymentId) && !retainDefaultPaymentMethod) {
          const response = await request(REMOVE_PAYMENT_METHOD, {
            paymentMethodId: defaultPaymentId,
            isDefault: true,
            hasActiveSubscriptions: true,
            hasUserPhysicalAwaitingOrders: false,
            legalEntity: entity,
            fromSetDefaultPaymentAction: true,
          }).then(res => res);

          if (
            entity === Salesforce.LegalEntity.ASSOCIATION.toLocaleLowerCase() &&
            association?.ach?.some(ach => ach.id === associationPaymentMethodId) &&
            response
          ) {
            dispatch(deleteMandate({ paymentType: Orders.PaymentMandateType.ach }));
          }
          return response;
        }
      }
    }
);

export const deletePaymentMethod: any = createAction(
  'checkout/DELETE_PAYMENT_METHOD',
  (card: State.CreditCard) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const paymentMethods: State.PaymentMethods = otherPaymentMethodSelector(state);
    const entity: string = selectedPaymentMethodEntity(state)?.toLocaleLowerCase() as string;

    const isDefault =
      entity === Salesforce.LegalEntity.CIMA.toLocaleLowerCase()
        ? paymentMethods.cima.defaultPaymentMethodId === card.id
        : paymentMethods.association.defaultPaymentMethodId === card.id;

    return request(REMOVE_PAYMENT_METHOD, {
      paymentMethodId: card.id,
      isDefault,
      hasActiveSubscriptions: true,
      hasUserPhysicalAwaitingOrders: false,
      legalEntity: entity,
    }).then(response => response);
  }
);

export const getPaypalToken: any = createAction(
  'checkout/GET_PAYPAL_TOKEN',
  (currencyCode: string, returnUrl: string, cancelUrl: string, firmLegalEntity?: string) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      const state = getState();
      dispatch(loading());
      const sessionIdentifier = paypalSessionIdentifierSelector(state);
      return request(GET_PAYPAL_TOKEN, {
        currencyCode,
        returnUrl,
        cancelUrl,
        firmLegalEntity,
        sessionIdentifier,
      }).then(response => {
        return response.getPayPalToken;
      });
    }
);

export const createPaypalPaymentMethod: any = createAction(
  'checkout/CREATE_PAYPAL_PAYMENT_METHOD',
  (token: string, storeCard: boolean, currency?: string, orgId?: string, isFirmBilling: boolean = false) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      dispatch(loading());
      const state = getState();
      const { pathname } = getLocation(state);

      const isPaypalProceedInvoiceCheckout: boolean = isPaypalProceedInvoiceCheckoutSelector(state) || isFirmBilling;
      const isCheckoutRoute =
        Boolean(matchPath(pathname, { path: getPath(Routes.CHECKOUT_PAGE), exact: true })) ||
        Boolean(matchPath(pathname, { path: getPath(Routes.PAYPAL_PAYMENT_CHECKOUT_PROCEED), exact: true }));
      const legalEntity = isFirmBilling
        ? legalEntityQuerySelector(state)
        : legalEntitySelector(state) || checkoutEntitySelector(state);
      const sessionIdentifier = paypalSessionIdentifierSelector(state);

      if (isPaypalProceedInvoiceCheckout || isCheckoutRoute) {
        updateContext({
          existingEntity: legalEntity?.toLowerCase(),
          currency: {
            label: currency,
          },
        });
      }

      return request(CREATE_PAYPAL_PAYMENT_METHOD, {
        token,
        storeCard,
        currency,
        orgId,
        firmLegalEntity: legalEntity,
        sessionIdentifier,
      }).then(response => {
        handleEvent(response, ADD_PAYMENT_INFO);
        return response.createPayPalPaymentMethod;
      });
    }
);

export const setPaypalSessionIdentifier: any = createAction('checkout/SET_PAYPAL_SESSION_IDENTIFIER');

export const sendDDConfirmationEmail: any = createAction(
  'checkout/SEND_DD_MANDATE_CONFIRMATION_EMAIL',
  async (mandateText: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const personDetails = personAccountDataSelector(state);
    const { cima }: State.PaymentMethods = otherPaymentMethodSelector(state);
    const cardDetails = cima?.bankTransfer[0];
    const staticText = 'Your Direct Debit mandate has been set up successfully';
    const ddMandateConfirmationEmail: SFMC.AchMandateConfirmationEmail = {
      subscriberKey: personDetails?.personContactId || '',
      emailAddress: personDetails?.primaryEmail?.emailAddress || '',
      staticText,
      customerName: `${personDetails?.firstName} ${personDetails?.lastName}` || '',
      bankDetails: cardDetails.IBAN,
      cardDigits: cardDetails.accountNumber.slice(-4),
      mandateText: mandateText.substring(0, 511),
    };
    return request(MUTATE_SEND_DD_MANDATE_CONFIRMATION_EMAIL, { ddMandateConfirmationEmail });
  }
);

export const sendACHConfirmationEmail: any = createAction(
  'checkout/SEND_ACH_MANDATE_CONFIRMATION_EMAIL',
  async (mandateText: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const personDetails = personAccountDataSelector(state);
    const { association }: State.PaymentMethods = otherPaymentMethodSelector(state);
    const achDetails = association?.ach[0];
    const staticText = 'Your ACH Debit mandate has been set up successfully';
    const achMandateConfirmationEmail: SFMC.AchMandateConfirmationEmail = {
      subscriberKey: personDetails?.personContactId || '',
      emailAddress: personDetails?.primaryEmail?.emailAddress || '',
      staticText,
      customerName: achDetails.bankAccountName,
      bankDetails: achDetails.bankAccountNumber,
      mandateText: mandateText.substring(0, 511),
    };
    return request(MUTATE_SEND_ACH_MANDATE_CONFIRMATION_EMAIL, { achMandateConfirmationEmail });
  }
);

export const updateOtherPaymentMethod: any = createAction(
  'checkout/UPDATE_OTHER_PAYMENT_METHOD',
  (accountId: string, paymentMethodId?: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(loading());
    return request(UPDATE_OTHER_PAYMENT_METHOD, { accountId, paymentMethodId }).then(response => response);
  }
);

export const updateZuoraInvoiceOwner: any = createAction(
  'checkout/UPDATE_ZUORA_INVOICE_OWNER',
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const activeMembershipSubscription =
      activeMembershipSubscriptionSelector(state) ?? activeFlpSubscriptionSelector(state);

    return request(UPDATE_ZUORA_INVOICE_OWNER, {
      orderDate: moment(activeMembershipSubscription?.orderDate).format('YYYY-MM-DD'),
      subscriptionNumber: activeMembershipSubscription?.subscriptionNumber,
    }).then(async response => {
      return response.updateZuoraInvoiceOwner;
    });
  }
);

// update invoice owner of membership, section, and credential back to user
export const updateAllZuoraInvoiceOwner: any = createAction(
  'checkout/UPDATE_ALL_ZUORA_INVOICE_OWNER',
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const { orderDate, subscriptionNumberArr }: any =
      activeMemSecCredSubscriptionSelector(state)?.reduce(
        (acc, curr) => {
          if (curr.subscriptionNumber) {
            if (!acc.subscriptionNumberArr.includes(curr.subscriptionNumber)) {
              acc.subscriptionNumberArr.push(curr.subscriptionNumber);
            }
            acc.orderDate = curr.orderDate as string;
          }
          return acc;
        },
        { orderDate: '', subscriptionNumberArr: [] as string[] }
      ) ?? {};

    return request(UPDATE_ALL_ZUORA_INVOICE_OWNER, {
      orderDate: moment(orderDate).format('YYYY-MM-DD'),
      subscriptionNumbers: subscriptionNumberArr,
    }).then(async response => {
      return response.updateAllZuoraInvoiceOwner;
    });
  }
);

export const getContentfulAttestations: any = createAction(
  'checkout/DIRECT_DEBIT_MANDATE',
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    return request(GET_CONTENTFUL_ATTESTATIONS).then(response => {
      if (response?.getContentfulAttestations) {
        const { attestations } = response?.getContentfulAttestations;
        return {
          directDebit: attestations?.find(
            (attestation: any) => attestation.name === 'Terms and Conditions - Direct Debit'
          )?.content,
          ach_checkout: attestations?.find(
            (attestation: any) => attestation.name === 'Terms and Conditions - ACH Checkout'
          )?.content,
          ach_wallet: attestations?.find((attestation: any) => attestation.name === 'Terms and Conditions - ACH Wallet')
            ?.content,
        };
      }
    });
  }
);
